import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { initGuard } from './init.guard';
import { authGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'teams',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: (): Promise<any> => import('@idea-ionic/auth').then(m => m.IDEAAuthModule),
    canActivate: [initGuard]
  },
  {
    path: 'echo',
    loadChildren: (): Promise<any> => import('@idea-ionic/common').then(m => m.IDEAEchoModule),
    canActivate: [initGuard]
  },
  {
    path: 'intro',
    loadChildren: (): Promise<any> => import('./intro/intro.module').then(m => m.IntroModule),
    canActivate: [initGuard]
  },
  {
    path: 'account',
    loadChildren: (): Promise<any> => import('@idea-ionic/teams').then(m => m.IDEAAccountModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams',
    loadChildren: (): Promise<any> => import('@idea-ionic/teams').then(m => m.IDEATeamsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId',
    loadChildren: (): Promise<any> => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/dashboard',
    redirectTo: 'teams/:teamId',
    pathMatch: 'full'
  },
  {
    path: 'teams/:teamId/archive',
    loadChildren: (): Promise<any> => import('./archive/archive.module').then(m => m.ArchiveModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/menu',
    loadChildren: (): Promise<any> => import('./menu/menu.module').then(m => m.MenuModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/activities/:activityId',
    loadChildren: (): Promise<any> => import('./activity/activity.module').then(m => m.ActivityModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/items',
    loadChildren: (): Promise<any> => import('./items/items.module').then(m => m.ItemsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/items/:itemId',
    loadChildren: (): Promise<any> => import('./items/item.module').then(m => m.ItemModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/systems',
    loadChildren: (): Promise<any> => import('./systems/systems.module').then(m => m.SystemsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/systems/:systemId',
    loadChildren: (): Promise<any> => import('./systems/system.module').then(m => m.SystemModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/customers',
    loadChildren: (): Promise<any> => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/customers/:customerId',
    loadChildren: (): Promise<any> => import('./customers/customer.module').then(m => m.CustomerModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/customers/:customerId/projects/:projectId',
    loadChildren: (): Promise<any> => import('./projects/project.module').then(m => m.ProjectModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/customers/:customerId/contacts/:contactId',
    loadChildren: (): Promise<any> => import('./contacts/contact.module').then(m => m.ContactModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/customers/:customerId/destinations/:destinationId',
    loadChildren: (): Promise<any> => import('./destinations/destination.module').then(m => m.DestinationModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/customers/:customerId/destinations/:destinationId/systems/:systemInstalledId',
    loadChildren: (): Promise<any> =>
      import('./systemsInstalled/systemInstalled.module').then(m => m.SystemInstalledModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/generic-projects',
    loadChildren: (): Promise<any> =>
      import('./genericProjects/genericProjects.module').then(m => m.GenericProjectsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/generic-projects/:projectId',
    loadChildren: (): Promise<any> =>
      import('./genericProjects/genericProject.module').then(m => m.GenericProjectModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/models',
    loadChildren: (): Promise<any> => import('./models/models.module').then(m => m.ModelsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/models/:modelId',
    loadChildren: (): Promise<any> => import('./models/model.module').then(m => m.ModelModule),
    canActivate: [initGuard, authGuard]
  },
  {
    // DO NOT CHANGE: standard IDEA; leave at least an alias for this path, which is used by the IDEATeamPage
    path: 'teams/:teamId/settings',
    loadChildren: (): Promise<any> => import('./team/team.module').then(m => m.TeamModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/memberships',
    loadChildren: (): Promise<any> => import('./memberships/memberships.module').then(m => m.MembershipsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/memberships/:userId/permissions',
    loadChildren: (): Promise<any> => import('./permissions/permissions.module').then(m => m.PermissionsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/profile',
    loadChildren: (): Promise<any> => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/import',
    loadChildren: (): Promise<any> => import('./import/import.module').then(m => m.ImportModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teams/:teamId/export',
    loadChildren: (): Promise<any> => import('./export/export.module').then(m => m.ExportModule),
    canActivate: [initGuard, authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
