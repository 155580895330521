import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn } from '@angular/router';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { User } from 'idea-toolbox';
import {
  CacheModes,
  IDEAAWSAPIService,
  IDEAOfflineDataService,
  IDEAStorageService,
  IDEATinCanService,
  IDEATranslationsService
} from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { Team } from '@models/team.model';
import { Membership } from '@models/membership.model';
import {
  MembershipsCR,
  ModelsCR,
  ActivitiesCR,
  ItemsCR,
  GenericProjectsCR,
  CustomersCR,
  DestinationsCR,
  ProjectsCR,
  ContactsCR
} from './cacheableResources.model';

import { environment as env } from '@env';

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const navCtrl = inject(NavController);
  const storage = inject(IDEAStorageService);
  const api = inject(IDEAAWSAPIService);
  const auth = inject(IDEAAuthService);
  const tc = inject(IDEATinCanService);
  const alertCtrl = inject(AlertController);
  const route = inject(ActivatedRoute);
  const offline = inject(IDEAOfflineDataService);
  const t = inject(IDEATranslationsService);

  //
  // HELPERS
  //

  const showAlertRobotCantSignIn = (): Promise<void> => {
    return new Promise(resolve => {
      alertCtrl
        .create({
          header: t._('IDEA_AUTH.ROBOT_USERS_CANT_SIGN_INTO_APPS'),
          message: t._('IDEA_AUTH.ROBOT_USERS_CANT_SIGN_INTO_APPS_I'),
          backdropDismiss: false,
          buttons: [{ text: t._('COMMON.GOT_IT'), handler: (): void => resolve() }]
        })
        .then(alert => alert.present());
    });
  };

  const redoAuthIfInvalidToken = async (): Promise<void> => {
    try {
      await api.getResource('users', {
        idea: true,
        resourceId: tc.get('userId'),
        params: { project: env.idea.project }
      });
    } catch (error) {
      // token expired (probably); re-do the auth
      const res = await auth.isAuthenticated(true, freshIdToken => tc.set('AWSAPIAuthToken', freshIdToken));
      tc.set('AWSAPIAuthToken', res.idToken);
    }
  };

  const navigateAndResolve = (navigationPath?: any[]): boolean => {
    if (navigationPath) navCtrl.navigateRoot(navigationPath);
    tc.set('ready', true);
    return true;
  };

  const configureOfflineManagement = (): void => {
    const team = tc.get('team');
    // initialize the offline service to sync data with the back-end
    offline.setUpOfflineData(team.teamId, true, [
      new MembershipsCR(t._('ENTITIES.TEAMMATES')),
      new ModelsCR(t._('ENTITIES.MODELS')),
      new ActivitiesCR(t._('ENTITIES.NEXT_ACTIVITIES')),
      new ItemsCR(t._('ENTITIES.ITEMS')),
      new GenericProjectsCR(t._('ENTITIES.GENERIC_PROJECTS')),
      new CustomersCR(t._('ENTITIES.CUSTOMERS')),
      new DestinationsCR(t._('ENTITIES.DESTINATIONS')),
      new ProjectsCR(t._('ENTITIES.PROJECTS')),
      new ContactsCR(t._('ENTITIES.CONTACTS'))
    ]);
    // decide whether the offline mode is allowed, based on device and team configurations
    if (team.allowOfflineMode && platform.is('mobile')) offline.synchronize();
  };

  //
  // MAIN
  //

  if (tc.get('ready')) return true;

  await platform.ready();
  await storage.ready();

  try {
    const result = await auth.isAuthenticated(true, freshIdToken => tc.set('AWSAPIAuthToken', freshIdToken));
    tc.set('AWSAPIAuthToken', result.idToken);
    tc.set('userId', result.userDetails.sub);

    try {
      const user = new User(
        await api.getResource('users', {
          idea: true,
          resourceId: tc.get('userId'),
          useCache: CacheModes.NETWORK_FIRST,
          params: { project: env.idea.project }
        })
      );
      tc.set('user', user);

      // when the app is resumed from idle, check the token to see if it's expired; in case, re-do the auth
      platform.resume.subscribe((): Promise<void> => redoAuthIfInvalidToken());

      // get the team to consider (from the URL or from the user's preferences)
      const teamId = route.snapshot.paramMap.get('teamId') || user.getCurrentTeamOfProject(env.idea.project);
      // if not team was found, go to team selection
      if (!teamId) return navigateAndResolve(['teams']);

      try {
        // get the team and membership data (in parallel, to save time)
        const res = await Promise.all([
          api.getResource('teams', { resourceId: teamId, useCache: CacheModes.NETWORK_FIRST }),
          api.getResource(`teams/${teamId}/memberships`, {
            resourceId: user.userId,
            useCache: CacheModes.NETWORK_FIRST
          })
        ]);
        // acquire the team
        const team = new Team(res[0]);
        tc.set('team', team);
        // set the languages based on teams preferences
        t.setLangs(team.languages.available);
        t.setDefaultLang(team.languages.default);
        if (!team.languages.available.some(x => x === t.getCurrentLang())) await t.use(team.languages.default);
        // acquire the membership
        const membership = new Membership(res[1]);
        tc.set('membership', membership);
        // configure the offline workflow
        configureOfflineManagement();
        // if we opened the app from a deep link, the url is already set (don't force it to '/')
        if (!tc.get('fromDeepLink') && window.location.pathname === '/')
          return navigateAndResolve(['teams', team.teamId]);
        else return navigateAndResolve();
      } catch (err) {
        return navigateAndResolve(['teams']);
      }
    } catch (err) {
      return navigateAndResolve(['intro']);
    }
  } catch (err) {
    if (err?.message === 'ROBOT_USER') await showAlertRobotCantSignIn();
    return navigateAndResolve(['intro']);
  }
};
