import { Address, epochDateTime, MembershipSummary, Resource } from 'idea-toolbox';

import { Team } from './team.model';

/**
 * Table: `scarlett_teams_customers`.
 *
 * Indexes:
 *    - `teamId-businessName-index-2`; includes: address, destinations, type. (LSI)
 */
export class Customer extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * Internal or external id.
   */
  customerId: string;
  /**
   * Business name of the customer.
   */
  businessName: string;
  /**
   * The address of the customer.
   */
  address: Address;
  /**
   * Email of contact.
   */
  email: string;
  /**
   * Limited to 500 chars. Notes on the customer, for internal uses.
   */
  internalNotes: string;
  /**
   * Timestamp of creation.
   */
  createdAt: epochDateTime;
  /**
   * The user who created the customer.
   */
  createdBy: MembershipSummary;
  /**
   * Timestamp of last update.
   */
  updatedAt?: epochDateTime;
  /**
   * The user who lastly updated the customer.
   */
  updatedBy?: MembershipSummary;
  /**
   * Whether the access to portal was enabled for this customer and its contacts.
   */
  portal: boolean;
  /**
   * The type of customer.
   */
  type: CustomerTypes;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.customerId = this.clean(x.customerId, String);
    this.businessName = this.clean(x.businessName, String);
    this.address = new Address(x.address);
    this.email = this.clean(x.email, String);
    this.internalNotes = this.clean(x.internalNotes, s => String(s).slice(0, 500));
    this.createdAt = this.clean(x.createdAt, d => new Date(d).getTime(), Date.now());
    this.createdBy = new MembershipSummary(x.createdBy);
    if (x.updatedAt) this.updatedAt = this.clean(x.updatedAt, d => new Date(d).getTime());
    if (x.updatedBy) this.updatedBy = new MembershipSummary(x.updatedBy);
    this.portal = this.clean(x.portal, Boolean);
    this.type = this.clean(x.type, String, CustomerTypes.CUSTOMER);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.customerId = safeData.customerId;
    this.createdAt = safeData.createdAt;
    this.createdBy = safeData.createdBy;
    if (safeData.updatedAt) this.updatedAt = safeData.updatedAt;
    if (safeData.updatedBy) this.updatedBy = safeData.updatedBy;
  }

  validate(team: Team): string[] {
    const e = super.validate();
    if (!team.automaticIds.customers && this.iE(this.customerId)) e.push('customerId');
    if (this.iE(this.businessName)) e.push('businessName');
    return e;
  }

  /**
   * Return an exportable flat version of the resource.
   */
  exportFlat(): CustomerFlat {
    return new CustomerFlat(this);
  }

  /**
   * Import a flat structure and set the internal attributes accordingly.
   */
  importFlat(x: CustomerFlat): void {
    this.customerId = this.clean(x['ID customer'], String);
    this.businessName = this.clean(x['Business name'], String);
    this.address = new Address({
      address: x['Street address'],
      postcode: x['Postal code'],
      city: x['City'],
      province: x['Province'],
      country: x['Country']
    });
    this.email = this.clean(x['Email'], String);
  }
}

/**
 * A brief representation of a Customer.
 */
export class CustomerSummary extends Resource {
  /**
   * The id of the customer.
   */
  customerId: string;
  /**
   * The name of the customer.
   */
  businessName: string;
  /**
   * The address of the customer.
   */
  address: Address;
  /**
   * The type of customer.
   */
  type: CustomerTypes;

  load(x: any): void {
    super.load(x);
    this.customerId = this.clean(x.customerId, String);
    this.businessName = this.clean(x.businessName, String);
    this.address = new Address(x.address);
    this.type = this.clean(x.type, String, CustomerTypes.CUSTOMER);
  }
}

/**
 * A flat version of the resource, useful for exports.
 */
export class CustomerFlat {
  'ID customer': string;
  'Type': string;
  'Business name': string;
  'Street address': string;
  'Postal code': string;
  City: string;
  Province: string;
  Country: string;
  Email: string;

  constructor(x?: Customer) {
    x = x || ({} as any);
    this['ID customer'] = x.customerId;
    this['Type'] = x.type;
    this['Business name'] = x.businessName;
    x.address = x.address || ({} as any);
    this['Street address'] = x.address.address;
    this['Postal code'] = x.address.postcode;
    this['City'] = x.address.city;
    this['Province'] = x.address.province;
    this['Country'] = x.address.country;
    this['Email'] = x.email;
  }
}

/**
 * Customer categorizations.
 */
export enum CustomerTypes {
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER'
}
