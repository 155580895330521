import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

import {
  IDEAEnvironment,
  IDEALoadingService,
  IDEAMessageService,
  IDEAAWSAPIService,
  IDEAErrorReportingService,
  IDEATinCanService,
  IDEAAnnouncementModule,
  IDEAOfflineService,
  IDEAOfflineDataService,
  IDEATranslationsService,
  IDEACachedResourcesService,
  IDEAActionSheetController
} from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';
import { ScarlettService } from './scarlett.service';
import { environment } from '@env';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // for angular-calendar
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    IonicStorageModule.forRoot({ name: 'scarlett' }),
    HttpClientModule,
    IDEAAnnouncementModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: IDEAEnvironment, useValue: environment },
    IDEATranslationsService,
    IDEATinCanService,
    IDEALoadingService,
    IDEAMessageService,
    IDEAActionSheetController,
    IDEAAuthService,
    IDEAAWSAPIService,
    IDEAErrorReportingService,
    IDEAOfflineService,
    IDEAOfflineDataService,
    IDEACachedResourcesService,
    ScarlettService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
