import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { register } from 'swiper/element/bundle';
import { App } from '@capacitor/app';
import { IDEATinCanService, IDEATranslationsService } from '@idea-ionic/common';

import { environment as env } from '@env';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    public router: Router,
    public tc: IDEATinCanService,
    public t: IDEATranslationsService,
    public zone: NgZone
  ) {
    // configure deep linking feature
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        // example: https://app.iter-idea.com/myPage/123
        // IDEA_APP_URL = https://app.iter-idea.com
        // slug         = /myPage/123
        const slug = data.url.slice(env.idea.app.url.length);
        // if we have a slag, we follow it; otherwise, let the regular routing logic take over
        if (slug) {
          // setting the following flag will help the guards to better handling the redirections
          this.tc.set('fromDeepLink', true);
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
