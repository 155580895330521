import { epochDateTime, Languages, MembershipSummary, Resource } from 'idea-toolbox';

import { ServiceLanguages } from './serviceLanguages.enum';

/**
 * Table: `scarlett_teams`.
 */
export class Team extends Resource {
  /**
   * The id of the team.
   * Note: the string can't contain underscore (_) chars, to avoid concatenation problems with other ids.
   */
  teamId: string;
  /**
   * The name of the team.
   */
  name: string;
  /**
   * The id of the owner.
   */
  ownerId: string;
  /**
   * Markdown support. Info to show regarding the team.
   */
  info: string;
  /**
   * The main contact email for the team.
   */
  email: string;
  /**
   * The default and available languages of the team.
   */
  languages: Languages;
  /**
   * Enum: Timezones coming from Moment.tz.names [e.g. 'Europe/Rome', etc.]
   */
  timezone: string;
  /**
   * The list of vehicles available to the team.
   */
  vehicles: string[];
  /**
   * Whether the team wants to allow the use of the service offline.
   */
  allowOfflineMode: boolean;
  /**
   * Whether the team wants to allow activities to be executed remotely (i.e. not locally/on-site).
   */
  allowRemoteActivities: boolean;
  /**
   * The list of units of measurement available to the team.
   */
  uoms: string[];
  /**
   * Support structure that indicates for which entities the ids need to be automatically suggested when inserting.
   */
  automaticIds: EntityAutomaticIds;
  /**
   * Whether to show the activities recap widget in the pages' UI.
   */
  showActivitiesRecapWidget: boolean;
  /**
   * If set, for a user it is sufficient to be involved in an activity (in one of its days) to access and manage it.
   * If not set, a user must be assigned to the activity to be able to access it and manage it.
   */
  activityPermissionsAreBasedOnInvolvement: boolean;
  /**
   * If set, the users are allowed to insert multiday reports;
   * enables `hideDailyReportDescription` and `hideOverallReportDescription`
   */
  multidayReports: boolean;
  /**
   * If set, in a multidays scenario this hides the daily report description from the UI.
   */
  hideDailyReportDescription?: boolean;
  /**
   * If set, in a multidays scenario this hides the overall report description from the UI.
   */
  hideOverallReportDescription?: boolean;
  /**
   * Timestamp of creation.
   */
  createdAt: epochDateTime;
  /**
   * The user who created the team.
   */
  createdBy: MembershipSummary;
  /**
   * Timestamp of last update.
   */
  updatedAt?: epochDateTime;
  /**
   * The user who lastly updated the team.
   */
  updatedBy?: MembershipSummary;
  /**
   * Whether the team uses Portal to manage the customers documentation.
   */
  portal: boolean;
  /**
   * The id of the default model for new activites (it will be selected automatically); it can be empty (no default).
   */
  defaultModelId: string;
  /**
   * The default description for new activities of the team.
   * Limited to 100 chars.
   */
  defaultActivitiesDescription: string;
  /**
   * The options to customise the agenda.
   */
  agenda: AgendaOptions;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.name = this.clean(x.name, String);
    this.ownerId = this.clean(x.ownerId, String);
    this.info = this.clean(x.info, String);
    this.email = this.clean(x.email, String);
    this.languages = new Languages(x.languages);
    this.timezone = this.clean(x.timezone, String);
    this.vehicles = this.cleanArray(x.vehicles, String);
    this.allowOfflineMode = this.clean(x.allowOfflineMode, Boolean);
    this.allowRemoteActivities = this.clean(x.allowRemoteActivities, Boolean);
    this.uoms = this.cleanArray(x.uoms, String);
    this.automaticIds = new EntityAutomaticIds(x.automaticIds);
    this.showActivitiesRecapWidget = this.clean(x.showActivitiesRecapWidget, Boolean);
    this.activityPermissionsAreBasedOnInvolvement = this.clean(x.activityPermissionsAreBasedOnInvolvement, Boolean);
    this.multidayReports = this.clean(x.multidayReports, Boolean);
    if (this.multidayReports) {
      this.hideDailyReportDescription = this.clean(x.hideDailyReportDescription, Boolean);
      this.hideOverallReportDescription = this.clean(x.hideOverallReportDescription, Boolean);
    }
    this.createdAt = this.clean(x.createdAt, d => new Date(d).getTime(), Date.now());
    this.createdBy = new MembershipSummary(x.createdBy);
    if (x.updatedAt) this.updatedAt = this.clean(x.updatedAt, d => new Date(d).getTime());
    if (x.updatedBy) this.updatedBy = new MembershipSummary(x.updatedBy);
    this.portal = this.clean(x.portal, Boolean);
    this.defaultModelId = this.clean(x.defaultModelId, String);
    this.defaultActivitiesDescription = this.clean(x.defaultActivitiesDescription, s => String(s).slice(0, 100));
    this.agenda = new AgendaOptions(x.agenda);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.ownerId = safeData.ownerId;
    this.createdAt = safeData.createdAt;
    this.createdBy = safeData.createdBy;
    if (safeData.updatedAt) this.updatedAt = safeData.updatedAt;
    if (safeData.updatedBy) this.updatedBy = safeData.updatedBy;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.info)) e.push('info');
    if (this.iE(this.email, 'email')) e.push('email');
    this.languages.validate(ServiceLanguages).forEach(ea => e.push(`languages.${ea}`));
    if (this.iE(this.timezone)) e.push('timezone');
    this.agenda.validate().forEach(ea => e.push(`agenda.${ea}`));
    return e;
  }
}

/**
 * Table: `scarlett_teams_financialYears`.
 */
export class FinancialYear extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * The year consdered.
   */
  year: string;
  /**
   * The last sequences (number) that has been used in the reports of each model, to uniquely identify a report
   * within a specific `financialYear`.
   *
   * The object consists of a series of key-values: `modelId: lastSequenceUsed`; e.g.
   * ```
   * modelABC: 3;
   * modelXYZ: 20; // note: 20 is the last used sequence for modelXYZ
   * ```
   * The structure is thought to support the atomic increment of DynamoDB.
   */
  sequences: {
    [key: string]: number;
  };

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.year = this.clean(x.year, String);
    this.sequences = {};
    if (x.sequences)
      for (const prop in x.sequences) if (x.sequences[prop]) this.sequences[prop] = Number(x.sequences[prop]);
  }
}

/**
 * The automatic ids configuration for the entities of the team.
 */
export class EntityAutomaticIds extends Resource {
  /**
   * Automatic id allowed for models.
   */
  models: boolean;
  /**
   * Automatic id allowed for items.
   */
  items: boolean;
  /**
   * Automatic id allowed for systems.
   */
  systems: boolean;
  /**
   * Automatic id allowed for customers.
   */
  customers: boolean;
  /**
   * Automatic id allowed for destinations.
   */
  destinations: boolean;
  /**
   * Automatic id allowed for projects.
   */
  projects: boolean;
  /**
   * Automatic id allowed for contacts.
   */
  contacts: boolean;

  load(x: any): void {
    super.load(x);
    this.models = this.clean(x.models, Boolean);
    this.items = this.clean(x.items, Boolean);
    this.systems = this.clean(x.systems, Boolean);
    this.customers = this.clean(x.customers, Boolean);
    this.destinations = this.clean(x.destinations, Boolean);
    this.projects = this.clean(x.projects, Boolean);
    this.contacts = this.clean(x.contacts, Boolean);
  }
}

/**
 * The options to customise the agenda.
 */
export class AgendaOptions extends Resource {
  /**
   * An array of day indexes (0 = sunday, 1 = monday, etc.) that will be hidden on the agenda.
   */
  excludeDays: number[];
  /**
   * The day start hours for the agenda, in 24 hour time. Must be 0-23.
   */
  dayStartHour: number;
  /**
   * The day end hours for the agenda, in 24 hour time. Must be 1-23.
   */
  dayEndHour: number;
  /**
   * The number of segments in an hour. Must divide equally into 60.
   */
  hourSegments: number;
  /**
   * If an appointment has the title among the ones in the list, it will be hidden by the interfaces.
   * Naive solution to get rid of repeated / internal appointments.
   */
  appointmentsToIgnoreByTitle: string[];

  load(x: any): void {
    super.load(x);
    this.excludeDays = (x.excludeDays || [])
      .map((d: number): number => Number(d))
      .filter((d: number): boolean => d >= 0 && d <= 6);
    this.dayStartHour = this.clean(x.dayStartHour, Number);
    this.dayEndHour = this.clean(x.dayEndHour, Number);
    this.hourSegments = this.clean(x.hourSegments, Number);
    this.appointmentsToIgnoreByTitle = this.cleanArray(x.appointmentsToIgnoreByTitle, String);
  }

  validate(): string[] {
    const e = super.validate();
    if (this.excludeDays.length > 6) e.push('excludeDays');
    if (this.dayStartHour < 0 || this.dayStartHour > 23) e.push('dayStartHour');
    if (this.dayEndHour < 1 || this.dayEndHour > 23) e.push('dayEndHour');
    if (this.hourSegments <= 0 || 60 % this.hourSegments !== 0) e.push('hourSegments');
    return e;
  }
}
