import { epochISODateString, Resource, toISODate } from 'idea-toolbox';

/**
 * A system installed at a customer's destination.
 *
 * Table: `scarlett_teams_systemsInstalled`.
 *
 * Indexes:
 *  - `teamId-lastCheckupAt-index`; ALL (GSI).
 *  - `teamId-nextCheckupAt-index`; ALL (GSI).
 *  - `teamCustomerDestinationId-lastCheckupAt-index`; ALL (GSI).
 *  - `teamCustomerDestinationId-nextCheckupAt-index`; ALL (GSI).
 */
export class SystemInstalled extends Resource {
  /**
   * The team ID.
   */
  teamId: string;
  /**
   * Internal ID to identify the system installed on a customer's destination.
   */
  systemInstalledId: string;
  /**
   * Concatenation (`_`) of `teamId`, `customerId` and `destinationId`.
   */
  teamCustomerDestinationId: string;
  /**
   * The customer ID.
   */
  customerId: string;
  /**
   * Destination ID.
   */
  destinationId: string;
  /**
   * The ID of the system.
   */
  systemId: string;
  /**
   * The brand of the system.
   */
  brand: string;
  /**
   * The model of the system.
   */
  model: string;
  /**
   * The serial number of the system installed.
   */
  serialNumber: string;
  /**
   * Some notes about the system. Max 500 chars.
   */
  notes: string;
  /**
   * The date the system was intalled.
   */
  installedAt: epochISODateString;
  /**
   * The date the system was lastly checked up.
   * At the beginning is equal to the date of installation.
   */
  lastCheckupAt: epochISODateString;
  /**
   * The planned date for the system's next check up.
   */
  nextCheckupAt?: epochISODateString;

  /**
   * Get the PK based on the key values.
   */
  static getPK(teamId: string, customerId: string, destinationId: string): string {
    return [teamId, customerId, destinationId].filter(x => x).join('_');
  }

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.systemInstalledId = this.clean(x.systemInstalledId, String);
    this.teamCustomerDestinationId = this.clean(x.teamCustomerDestinationId, String);
    this.customerId = this.clean(x.customerId, String);
    this.destinationId = this.clean(x.destinationId, String);
    this.systemId = this.clean(x.systemId, String);
    this.brand = this.clean(x.brand, String);
    this.model = this.clean(x.model, String);
    this.serialNumber = this.clean(x.serialNumber, String);
    this.notes = this.clean(x.notes, s => String(s).slice(0, 500));
    this.installedAt = this.clean(x.installedAt, toISODate);
    this.lastCheckupAt = this.clean(x.lastCheckupAt, toISODate, this.installedAt);
    if (x.nextCheckupAt) this.nextCheckupAt = this.clean(x.nextCheckupAt, toISODate);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.systemInstalledId = safeData.systemInstalledId;
    this.teamCustomerDestinationId = safeData.teamCustomerDestinationId;
    this.customerId = safeData.customerId;
    this.destinationId = safeData.destinationId;
    this.systemId = safeData.systemId;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.customerId)) e.push('customerId');
    if (this.iE(this.destinationId)) e.push('destinationId');
    if (this.iE(this.systemId)) e.push('systemId');
    if (this.iE(this.brand)) e.push('brand');
    if (this.iE(this.model)) e.push('model');
    if (this.iE(this.serialNumber)) e.push('serialNumber');
    if (this.iE(this.installedAt, 'date')) e.push('installedAt');
    if (this.iE(this.lastCheckupAt, 'date')) e.push('lastCheckupAt');
    return e;
  }

  /**
   * Get a descriptive name for the system installed.
   */
  getName(): string {
    return `${this.brand} ${this.model}`;
  }

  /**
   * Return an exportable flat version of the resource.
   */
  exportFlat(): SystemInstalledFlat {
    return new SystemInstalledFlat(this);
  }
}

/**
 * A brief representation of a SystemInstalled.
 */
export class SystemInstalledSummary extends Resource {
  /**
   * The ID of the system installed.
   */
  systemInstalledId: string;
  /**
   * The ID of the system.
   */
  systemId: string;
  /**
   * The brand of the system.
   */
  brand: string;
  /**
   * The model of the system.
   */
  model: string;
  /**
   * The serial number of the system installed.
   */
  serialNumber: string;

  load(x: any): void {
    super.load(x);
    this.systemInstalledId = this.clean(x.systemInstalledId, String);
    this.systemId = this.clean(x.systemId, String);
    this.brand = this.clean(x.brand, String);
    this.model = this.clean(x.model, String);
    this.serialNumber = this.clean(x.serialNumber, String);
  }

  /**
   * Get a descriptive name for the system installed.
   */
  getName(): string {
    return `${this.brand} ${this.model}`;
  }
}

/**
 * A system installed at a customer's destination and involved in an activity.
 */
export class SystemInstalledUsed extends Resource {
  /**
   * Internal ID to identify the system installed on a customer's destination.
   */
  systemInstalledId: string;
  /**
   * The ID of the system.
   */
  systemId: string;
  /**
   * The brand of the system.
   */
  brand: string;
  /**
   * The model of the system.
   */
  model: string;
  /**
   * The serial number of the system installed.
   */
  serialNumber: string;
  /**
   * The notes about the system to insert in reports. Max 500 chars.
   */
  notes: string;
  /**
   * The planned date for the system's next check up.
   */
  nextCheckupAt?: epochISODateString;

  load(x: any): void {
    super.load(x);
    this.systemInstalledId = this.clean(x.systemInstalledId, String);
    this.systemId = this.clean(x.systemId, String);
    this.brand = this.clean(x.brand, String);
    this.model = this.clean(x.model, String);
    this.serialNumber = this.clean(x.serialNumber, String);
    this.notes = this.clean(x.notes, s => String(s).slice(0, 500));
    if (x.nextCheckupAt) this.nextCheckupAt = this.clean(x.nextCheckupAt, toISODate);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.systemInstalledId = safeData.systemInstalledId;
    this.systemId = safeData.systemId;
    this.brand = safeData.brand;
    this.model = safeData.model;
    this.serialNumber = safeData.serialNumber;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.systemInstalledId)) e.push('systemInstalledId');
    if (this.iE(this.systemId)) e.push('systemId');
    if (this.iE(this.brand)) e.push('brand');
    if (this.iE(this.model)) e.push('model');
    if (this.iE(this.serialNumber)) e.push('serialNumber');
    return e;
  }

  /**
   * Get a descriptive name for the system installed.
   */
  getName(): string {
    return `${this.brand} ${this.model}`;
  }
}

/**
 * A flat version of the resource, useful for exports.
 */
export class SystemInstalledFlat {
  'ID system': string;
  Brand: string;
  Model: string;
  Customer: string;
  Destination: string;
  'Serial Number': string;
  Notes: string;
  'Installation Date': string;
  'Last Checkup Date': string;
  'Next Checkup Date': string;

  constructor(x?: SystemInstalled) {
    x = x || ({} as any);
    this['ID system'] = x.systemId;
    this['Brand'] = x.brand;
    this['Model'] = x.model;
    this['Customer'] = x.customerId;
    this['Destination'] = x.destinationId;
    this['Serial Number'] = x.serialNumber;
    this['Notes'] = x.notes;
    this['Installation Date'] = x.installedAt;
    this['Last Checkup Date'] = x.lastCheckupAt;
    this['Next Checkup Date'] = x.nextCheckupAt ? x.nextCheckupAt : '';
  }
}
