import { epochDateTime, MembershipSummary, Resource } from 'idea-toolbox';

import { Team } from '../_shared/team.model';

/**
 * Table: `scarlett_teamsCustomers_contacts`.
 *
 * Indexes:
 *    - `teamCustomerId-name-index`; includes: email, phone, destinationId. (LSI)
 */
export class Contact extends Resource {
  /**
   * Concatenation of teamId and customerId.
   */
  teamCustomerId: string;
  /**
   * The id of the contact.
   */
  contactId: string;
  /**
   * The teamId of the contact; useful for searches and indexes.
   */
  teamId: string;
  /**
   * The name of the contact.
   */
  name: string;
  /**
   * The email of the contact.
   */
  email: string;
  /**
   * The phone number of the contact.
   */
  phone: string;
  /**
   * The customer default destination.
   */
  destinationId: string;
  /**
   * Timestamp of creation.
   */
  createdAt: epochDateTime;
  /**
   * The user who created the customer.
   */
  createdBy: MembershipSummary;
  /**
   * Timestamp of last update.
   */
  updatedAt?: epochDateTime;
  /**
   * The user who lastly updated the customer.
   */
  updatedBy?: MembershipSummary;
  /**
   * The id of the contact in the (external) Portal project.
   */
  portalUserId?: string;
  /**
   * Whether the access to portal was enabled for this contact.
   */
  portal: boolean;

  load(x: any): void {
    super.load(x);
    this.teamCustomerId = this.clean(x.teamCustomerId, String);
    this.contactId = this.clean(x.contactId, String);
    this.teamId = this.clean(x.teamId, String);
    this.name = this.clean(x.name, String);
    this.email = this.clean(x.email, String);
    this.phone = this.clean(x.phone, String);
    this.destinationId = this.clean(x.destinationId, String);
    this.createdAt = this.clean(x.createdAt, d => new Date(d).getTime(), Date.now());
    this.createdBy = new MembershipSummary(x.createdBy);
    if (x.updatedAt) this.updatedAt = this.clean(x.updatedAt, d => new Date(d).getTime());
    if (x.updatedBy) this.updatedBy = new MembershipSummary(x.updatedBy);
    if (x.portalUserId) this.portalUserId = this.clean(x.portalUserId, String);
    this.portal = this.clean(x.portal, Boolean);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamCustomerId = safeData.teamCustomerId;
    this.contactId = safeData.contactId;
    this.teamId = safeData.teamId;
    this.createdAt = safeData.createdAt;
    this.createdBy = safeData.createdBy;
    if (safeData.updatedAt) this.updatedAt = safeData.updatedAt;
    if (safeData.updatedBy) this.updatedBy = safeData.updatedBy;
    if (safeData.portalUserId) this.portalUserId = safeData.portalUserId;
  }

  validate(team: Team): string[] {
    const e = super.validate();
    if (!team.automaticIds.contacts && this.iE(this.contactId)) e.push('contactId');
    if (this.iE(this.name)) e.push('name');
    return e;
  }

  /**
   * Return an exportable flat version of the resource.
   */
  exportFlat(): ContactFlat {
    return new ContactFlat(this);
  }

  /**
   * Import a flat structure and set the internal attributes accordingly.
   */
  importFlat(x: ContactFlat, teamId: string): void {
    this.teamCustomerId = this.clean(teamId.concat('_', x['ID customer']), String);
    this.contactId = this.clean(x['ID contact'], String);
    this.name = this.clean(x['Name'], String);
    this.email = this.clean(x['Email'], String);
    this.phone = this.clean(x['Phone'], String);
    this.destinationId = this.clean(x['ID preferred destination'], Number);
  }
}
/**
 * A brief representation of a Contact.
 */
export class ContactSummary extends Resource {
  /**
   * Concatenation of teamId and customerId.
   */
  teamCustomerId: string;
  /**
   * The id of the contact.
   */
  contactId: string;
  /**
   * The name of the contact.
   */
  name: string;
  /**
   * The email of the contact.
   */
  email: string;
  /**
   * The phone number of the contact.
   */
  phone: string;
  /**
   * The customer default destination.
   */
  destinationId: string;

  load(x: any): void {
    super.load(x);
    this.teamCustomerId = this.clean(x.teamCustomerId, String);
    this.contactId = this.clean(x.contactId, String);
    this.name = this.clean(x.name, String);
    this.email = this.clean(x.email, String);
    this.phone = this.clean(x.phone, String);
    this.destinationId = this.clean(x.destinationId, String);
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.teamCustomerId)) e.push('teamCustomerId');
    if (this.iE(this.contactId)) e.push('contactId');
    if (this.iE(this.name)) e.push('name');
    return e;
  }
}

/**
 * A flat version of the resource, useful for exports.
 */
export class ContactFlat {
  'ID customer': string;
  'ID contact': string;
  Name: string;
  Email: string;
  Phone: string;
  'ID preferred destination': string;

  constructor(x?: Contact) {
    x = x || ({} as any);
    this['ID customer'] = (x.teamCustomerId || '').split('_')[1];
    this['ID contact'] = x.contactId;
    this['Name'] = x.name;
    this['Email'] = x.email;
    this['Phone'] = x.phone;
    this['ID preferred destination'] = x.destinationId;
  }
}
