import { epochDateTime, MembershipSummary, Resource } from 'idea-toolbox';

import { Team } from './team.model';

/**
 * Table: `scarlett_teams_projects`.
 *
 * Indexes:
 *    - `teamId-name-index`; includes: KEYS ONLY. (LSI)
 */
export class GenericProject extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * The id of the project.
   */
  projectId: string;
  /**
   * The name of the project.
   */
  name: string;
  /**
   * Timestamp of creation.
   */
  createdAt: epochDateTime;
  /**
   * The user who created the customer.
   */
  createdBy: MembershipSummary;
  /**
   * Timestamp of last update.
   */
  updatedAt?: epochDateTime;
  /**
   * The user who lastly updated the customer.
   */
  updatedBy?: MembershipSummary;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.projectId = this.clean(x.projectId, String);
    this.name = this.clean(x.name, String);
    this.createdAt = this.clean(x.createdAt, d => new Date(d).getTime(), Date.now());
    this.createdBy = new MembershipSummary(x.createdBy);
    if (x.updatedAt) this.updatedAt = this.clean(x.updatedAt, d => new Date(d).getTime());
    if (x.updatedBy) this.updatedBy = new MembershipSummary(x.updatedBy);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.projectId = safeData.projectId;
    this.createdAt = safeData.createdAt;
    this.createdBy = safeData.createdBy;
    if (safeData.updatedAt) this.updatedAt = safeData.updatedAt;
    if (safeData.updatedBy) this.updatedBy = safeData.updatedBy;
  }

  validate(team: Team): string[] {
    const e = super.validate();
    if (!team.automaticIds.projects && this.iE(this.projectId)) e.push('projectId');
    if (this.iE(this.name)) e.push('name');
    return e;
  }

  /**
   * Return the project name - with ID if it was set by the user
   */
  displayProjectName(team: Team): string {
    return team.automaticIds.projects ? this.name : `${this.name} (${this.projectId})`;
  }

  /**
   * Return an exportable flat version of the resource.
   */
  exportFlat(): GenericProjectFlat {
    return new GenericProjectFlat(this);
  }

  /**
   * Import a flat structure and set the internal attributes accordingly.
   */
  importFlat(x: GenericProjectFlat): void {
    this.projectId = this.clean(x['ID project'], String);
    this.name = this.clean(x['Name'], String);
  }
}

/**
 * A brief representation of a GenericProject.
 */
export class GenericProjectSummary extends Resource {
  /**
   * The id of the project.
   */
  projectId: string;
  /**
   * The name of the project.
   */
  name: string;

  load(x: any): void {
    super.load(x);
    this.projectId = this.clean(x.projectId, String);
    this.name = this.clean(x.name, String);
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.projectId)) e.push('projectId');
    if (this.iE(this.name)) e.push('name');
    return e;
  }

  /**
   * Return the project name - with ID if it was set by the user
   */
  displayProjectName(team: Team): string {
    return team.automaticIds.projects ? this.name : `${this.name} (${this.projectId})`;
  }
}

/**
 * A flat version of the resource, useful for exports.
 */
export class GenericProjectFlat {
  'ID project': string;
  Name: string;

  constructor(x?: GenericProject) {
    x = x || ({} as any);
    this['ID project'] = x.projectId;
    this['Name'] = x.name;
  }
}

/**
 * Table: `scarlett_teamsCustomers_projects`.
 *
 * Indexes:
 *    - `teamCustomerId-name-index`; includes: teamId, name, teamCustomerId, projectId, customerId.
 *    - `teamId-name-index`; includes: customerId. (LSI)
 */
export class Project extends GenericProject {
  /**
   * Concatenation of teamId and customerId.
   */
  teamCustomerId: string;
  /**
   * The customer of the project.
   */
  customerId: string;

  load(x: any): void {
    super.load(x);
    this.teamCustomerId = this.clean(x.teamCustomerId, String);
    this.customerId = this.clean(x.customerId, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.customerId = safeData.customerId;
    this.teamCustomerId = safeData.teamCustomerId;
  }

  validate(team: Team): string[] {
    return super.validate(team);
  }

  /**
   * Return an exportable flat version of the resource.
   */
  exportFlat(): ProjectFlat {
    return new ProjectFlat(this);
  }

  /**
   * Import a flat structure and set the internal attributes accordingly.
   */
  importFlat(x: ProjectFlat, teamId?: string): void {
    this.teamCustomerId = this.clean(teamId.concat('_', x['ID customer']), String);
    this.projectId = this.clean(x['ID project'], String);
    this.name = this.clean(x['Name'], String);
  }
}

/**
 * A brief representation of a Project.
 */
export class ProjectSummary extends GenericProjectSummary {
  /**
   * Concatenation of teamId and customerId.
   */
  teamCustomerId: string;
  /**
   * The id of the customer.
   */
  customerId: string;

  load(x: any): void {
    super.load(x);
    this.teamCustomerId = this.clean(x.teamCustomerId, String);
    this.customerId = this.clean(x.customerId, String);
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.teamCustomerId)) e.push('teamCustomerId');
    if (this.iE(this.customerId)) e.push('customerId');
    return e;
  }
}

/**
 * A flat version of the resource, useful for exports.
 */
export class ProjectFlat {
  'ID customer': string;
  'ID project': string;
  Name: string;

  constructor(x?: Project) {
    x = x || ({} as any);
    this['ID customer'] = (x.teamCustomerId || '').split('_')[1];
    this['ID project'] = x.projectId;
    this['Name'] = x.name;
  }
}
