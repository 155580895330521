import {
  AddressGeolocation,
  AppointmentKeys,
  Attachment,
  epochDateTime,
  markdown,
  MembershipSummary,
  RCAttachedResource,
  Resource,
  Signature,
  TimeInterval
} from 'idea-toolbox';

import { Model } from './model.model';
import { Team } from '../_shared/team.model';
import { ItemUsed } from './item.model';
import { ContactSummary } from './contact.model';
import { Customer, CustomerTypes } from './customer.model';
import { Destination } from './destination.model';
import { Membership } from './membership.model';
import { ProjectSummary, GenericProjectSummary } from './project.model';
import { SystemInstalledUsed } from './systemInstalled.model';

/**
 * Table: `scarlett_teams_activities`.
 *
 * Indexes:
 *  - `teamId-endsAt-index`; includes:
 *        description, startsAt, allDay, target, assignees, iCalUID, completedAt, financialYear, sequence,
 *        modelId, color, projectsIds, genericProjectsIds, usersIds, itemsIds,
 *        numDays, followUpSet, hasBeenSent, systemsIds, systemSerialNrs.
 *  - `teamId-lastDay-index`; includes:
 *        description, firstDay, target, assignees, completedAt, financialYear, sequence,
 *        modelId, color, projectsIds, genericProjectsIds, usersIds, itemsIds,
 *        numDays, followUpSet, hasBeenSent, systemsIds, systemSerialNrs.
 *  - `teamId-notScheduledAt-index`; includes:
 *        description, target, assignees, completedAt, financialYear, sequence,
 *        modelId, color, projectsIds, genericProjectsIds, usersIds, itemsIds,
 *        numDays, followUpSet, hasBeenSent, systemsIds, systemSerialNrs.
 *  - `teamId-publishedOnPortalForTarget-index`: includes:
 *        financialYear, completedAt, signatory, sequence, attachments, signedAt,
 *        attachedResources, completedBy.
 *
 * If the activity is completed (`completedAt`), a graphic signature could be found on S3 in the following path:
 *    `signatures/${teamId}/${activityId}.png`.
 */
export class Activity extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * Internal or external id of the activity.
   */
  activityId: string;

  //
  // PRE-ACTIVITY
  //

  /**
   * A brief description of the activity.
   * Limited to 100 chars.
   */
  description: string;
  /**
   * The model of the activity. In the first phases it could be not set, but to start an activity, it's obligatory.
   */
  model?: Model;
  /**
   * The planned time when the activity will start.
   */
  startsAt?: epochDateTime;
  /**
   * The planned time when the activity will end.
   */
  endsAt?: epochDateTime;
  /**
   * Whether the scheduled activity is all-day(s) long, i.e. there is no starting/ending time.
   */
  allDay?: boolean;
  /**
   * Timestamp when a new activity has been created without being scheduled.
   * Note: `notScheduledAt` must be null if `startsAt` && `endsAt` are set, and viceversa.
   */
  notScheduledAt?: epochDateTime;
  /**
   * Summary structure representing a customer and a destination.
   */
  target: Target;
  /**
   * The customer's contacts involved in some ways in the activity.
   */
  contacts: ContactSummary[];
  /**
   * The id of the project for which the activity is executed.
   * It's optional, but it could be mandatory, based on the team's configuration.
   */
  mainProject?: GenericProjectSummary | ProjectSummary;
  /**
   * The users assigned in the activity.
   */
  assignees: MembershipSummary[];
  /**
   * Internal notes, not shown to the external.
   * Limited to 500 chars.
   */
  notes: markdown;
  /**
   * Extra info on the activity to show only in the PDF report.
   * Note: this field isn't editable in the UI of the service; it's useful for linked services.
   * Limited to 500 chars.
   */
  externalExtraInfo?: markdown;
  /**
   * The iCal UID (standard RFC5545) of the linked agenda appointment, if any.
   * It represents a weak link to an appointment (no data sync).
   */
  iCalUID?: string;
  /**
   * The appointment (if any) linked in a way that ensures synchronisation of the main data.
   * It represent a strong link to an appointment; when set, changes to the activity are pushed to the appointment.
   */
  appointment?: AppointmentKeys;

  //
  // DURING ACTIVITY
  //

  /**
   * The detail of the different days in which the activity is executed.
   */
  days: ActivityDay[];
  /**
   * The custom sections and fields at the activity level, managed by `model.customBlockActivity`.
   */
  sections: any;
  /**
   * The items used during the activity.
   */
  items: ItemUsed[];
  /**
   * The systems involved in the activity.
   */
  systems: SystemInstalledUsed[];
  /**
   * The attached resources (from the Resource Center) for the activity.
   * They will be included in the email sent to the target.
   * It's set if the model uses the Resource Center.
   */
  attachedResources?: RCAttachedResource[];
  /**
   * The user-generated attachments of the report.
   * They will NOT be included in the email sent to the target.
   * It's set if the model uses attachments.
   */
  attachments?: Attachment[];
  /**
   * Suggested follow-up of the activity.
   * It's set if the model uses follow-ups.
   */
  suggestedFollowUp?: SuggestedFollowUp;
  /**
   * The overall description, used in multi-days reports as final description.
   * Can be hidden by `team.hideOverallReportDescription`.
   * Limit to 1000 chars.
   */
  overallReportDescription?: markdown;
  /**
   * The ids of the items that are associated to the activity.
   */
  mobileWarehouse: string[];
  /**
   * The temporary signature for the activity, before the activity is completed.
   * If set, the activity's content can't change: the activity can either be confirmed or the signature must be reset.
   * It is needed to store and sync the signature until it can be changed (i.e. until the activity isn't completed).
   * When the activity is completed, this temporary object is removed and the final signature is moved to S3.
   */
  temporarySignature?: Signature;

  //
  // POST-ACTIVITY (if `completedAt`)
  //

  /**
   * The timestamp when the final signature was acquired; it can differ from the (following) date of completion.
   */
  signedAt?: epochDateTime;
  /**
   * Name and surname of who has signed the activity's final report.
   * When a report has a final signature, it can't be changed.
   */
  signatory?: string;
  /**
   * The date of completion of the activity.
   * When an activity is completed, it can't be changed in any way.
   */
  completedAt?: epochDateTime;
  /**
   * Who completed the activity.
   */
  completedBy?: MembershipSummary;
  /**
   * Financial year in which the activity was completed.
   */
  financialYear?: number;
  /**
   * Unique identifier of an activity completed within a specific financial year.
   */
  sequence?: number;

  //
  // SUPPORT AND INDEXES
  //

  /**
   * Timestamp of creation of the activity.
   */
  createdAt: epochDateTime;
  /**
   * Who created the activity.
   */
  createdBy: MembershipSummary;
  /**
   * Timestamp of last update of the activity.
   */
  updatedAt?: epochDateTime;
  /**
   * Who lastly edited the activity.
   */
  updatedBy?: MembershipSummary;
  /**
   * The id of the model used for this activity.
   */
  modelId: string;
  /**
   * The distinctive color of the model (if set) chosen for the activity.
   * RGB or HEX.
   */
  color?: string;
  /**
   * The ids of the contacts involved in the activity.
   */
  contactsIds: string[];
  /**
   * The ids of the projects mentioned in the activity (during any day).
   */
  projectsIds: string[];
  /**
   * The ids of the generic projects mentioned in the activity (during any day).
   */
  genericProjectsIds: string[];
  /**
   * The ids of the teammates involved in the activity (during any day); it includes the assignees.
   */
  usersIds: string[];
  /**
   * The ids of the items used during the days.
   */
  itemsIds: string[];
  /**
   * The ids of the systems involved during the days.
   */
  systemsIds: string[];
  /**
   * The serial numbers of the systems involved during the days.
   */
  systemSerialNrs: string[];
  /**
   * The number of days currently reported in the activity.
   */
  numDays: number;
  /**
   * The date of the first day reported in the activity.
   */
  firstDay?: epochDateTime;
  /**
   * The date of the last day reported in the activity.
   */
  lastDay?: epochDateTime;
  /**
   * Whether the suggested follow-up has been set or not.
   */
  followUpSet?: boolean;
  /**
   * To indicate whether the PDF report has been sent via email after the activity completion.
   *   - If not set, the report hasn't been sent and doesn't have to (not mandatory).
   *   - If set and false, the report hasn't been sent yet, but it has to (mandatory).
   *   - If set and true, the report has been sent at least once after the activity was completed.
   */
  hasBeenSent?: boolean;
  /**
   * In case the activity is completed, the customerId (Target) for filtering the document published in Portal.
   */
  publishedOnPortalForTarget?: string;

  load(x: any, meta: { team: Team; membership: Membership }): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.activityId = this.clean(x.activityId, String);

    this.description = this.clean(x.description, s => String(s).slice(0, 100));
    if (x.model) {
      this.model = new Model(x.model, meta.team);
      this.color = this.model.color;
    } else this.color = this.clean(x.color, String);
    this.target = new Target(x.target);
    this.startsAt = this.clean(x.startsAt, d => Math.floor(new Date(d).getTime() / 1000) * 1000) as epochDateTime;
    this.endsAt = this.clean(x.endsAt, d => Math.floor(new Date(d).getTime() / 1000) * 1000) as epochDateTime;
    this.allDay = this.clean(x.allDay, Boolean);
    this.contacts = this.cleanArray(x.contacts, c => new ContactSummary(c));
    if (x.mainProject) {
      if (x.mainProject.teamCustomerId) this.mainProject = this.clean(x.mainProject, p => new ProjectSummary(p));
      else this.mainProject = this.clean(x.mainProject, p => new GenericProjectSummary(p));
    }
    this.assignees = this.cleanArray(x.assignees, u => new MembershipSummary(u));
    this.notes = this.clean(x.notes, s => String(s).slice(0, 500)) as markdown;
    this.externalExtraInfo = this.clean(x.externalExtraInfo, s => String(s).slice(0, 500)) as markdown;
    if (x.iCalUID) this.iCalUID = this.clean(x.iCalUID, String);
    if (x.appointment) this.appointment = new AppointmentKeys(x.appointment);

    this.days = this.cleanArray(x.days, d => new ActivityDay(d, this.model));
    if (this.model) {
      if (x.sections) this.sections = this.model.customBlockActivity.loadSections(x.sections);
      else this.sections = this.model.customBlockActivity.setSectionsDefaultValues();
      if (this.model.options.requireSendingReport) this.hasBeenSent = this.clean(x.hasBeenSent, Boolean);
      else if (x.hasBeenSent === true) this.hasBeenSent = true;
    }
    this.items = this.cleanArray(x.items, i => new ItemUsed(i));
    this.systems = this.cleanArray(x.systems, s => new SystemInstalledUsed(s));
    if (this.model && this.model.options.resourceCenter)
      this.attachedResources = this.cleanArray(x.attachedResources, r => new RCAttachedResource(r));
    if (this.model && this.model.options.attachments)
      this.attachments = this.cleanArray(x.attachments, a => new Attachment(a));
    if (this.model && this.model.options.suggestedFollowUp)
      this.suggestedFollowUp = new SuggestedFollowUp(x.suggestedFollowUp);
    if (meta.team.multidayReports && !meta.team.hideOverallReportDescription)
      this.overallReportDescription = this.clean(x.overallReportDescription, s => String(s).slice(0, 1000));
    this.mobileWarehouse = this.cleanArray(x.mobileWarehouse, String);
    if (x.temporarySignature) this.temporarySignature = new Signature(x.temporarySignature);

    if (x.completedAt) {
      delete this.temporarySignature;
      this.signedAt = this.clean(x.signedAt, d => new Date(d).getTime()) as epochDateTime;
      this.signatory = this.clean(x.signatory, String);
      this.completedAt = this.clean(x.completedAt, d => new Date(d).getTime()) as epochDateTime;
      this.completedBy = new MembershipSummary(x.completedBy);
      this.financialYear = this.clean(x.financialYear, Number);
      this.sequence = this.clean(x.sequence, Number);
    }

    this.createdAt = this.clean(x.createdAt, d => new Date(d).getTime(), Date.now()) as epochDateTime;
    this.createdBy = new MembershipSummary(x.createdBy);
    if (x.updatedAt) this.updatedAt = this.clean(x.updatedAt, d => new Date(d).getTime()) as epochDateTime;
    if (x.updatedBy) this.updatedBy = new MembershipSummary(x.updatedBy);

    this.modelId = this.model ? this.model.modelId : this.clean(x.modelId, String);

    this.calcSupportAttributes();
  }
  /**
   * Helper to calculate the derived attributes.
   */
  private calcSupportAttributes(): void {
    this.contactsIds = this.contacts.map(c => c.contactId);
    this.projectsIds = this.getProjectsIds();
    this.genericProjectsIds = this.getGenericProjectsIds();
    this.usersIds = this.getUsersIds();
    this.itemsIds = this.getItemsIds();
    this.systemsIds = this.getSystemsIds();
    this.systemSerialNrs = this.getSystemsSerialNrs();
    this.days = this.days.sort((a, b): number => a.date - b.date);
    this.numDays = this.days.length;
    if (this.numDays > 0) {
      this.firstDay = this.days.reduce((min, day): number => (min === 0 ? day.date : Math.min(min, day.date)), 0);
      this.lastDay = this.days.reduce((max, day): number => (max === 0 ? day.date : Math.max(max, day.date)), 0);
    } else {
      delete this.firstDay;
      delete this.lastDay;
    }
    if (this.suggestedFollowUp && this.suggestedFollowUp.isSet()) this.followUpSet = true;

    // `startsAt`+`endsAt` and `notScheduledAt` must be exclusive
    if (this.startsAt || this.endsAt) delete this.notScheduledAt;
    else {
      delete this.startsAt;
      delete this.endsAt;
      delete this.allDay;
      this.notScheduledAt = this.updatedAt || this.createdAt;
    }

    if (this.completedAt) this.publishedOnPortalForTarget = this.target.customerId;
  }

  safeLoad(newData: any, safeData: any, meta: { team: Team; membership: Membership }): void {
    super.safeLoad(newData, safeData, meta);
    this.teamId = safeData.teamId;
    this.activityId = safeData.activityId;

    // avoid unallowed changes, based on the user's permissions
    const permissionsOnActivity = meta.membership.permissions.activities;
    if (!permissionsOnActivity.canEditModel) {
      this.modelId = safeData.modelId;
      if (safeData.model) this.model = safeData.model;
    }
    if (!permissionsOnActivity.canEditDates) {
      if (safeData.startsAt) this.startsAt = safeData.startsAt;
      if (safeData.endsAt) this.endsAt = safeData.endsAt;
      if (safeData.allDay !== undefined) this.allDay = safeData.allDay;
      if (safeData.notScheduledAt) this.notScheduledAt = safeData.notScheduledAt;
    }
    if (!permissionsOnActivity.canEditTarget) {
      this.target = safeData.target;
      this.contacts = safeData.contacts;
    }
    if (!permissionsOnActivity.canEditProject) {
      if (safeData.mainProject) this.mainProject = safeData.mainProject;
    }
    if (!permissionsOnActivity.canEditAssignees) {
      this.assignees = safeData.assignees;
    }
    if (!permissionsOnActivity.canEditDescriptions) {
      this.description = safeData.description;
      this.notes = safeData.notes;
    }

    // changed in a specific completion action
    if (safeData.completedAt) {
      delete this.temporarySignature;
      this.signedAt = safeData.signedAt;
      this.signatory = safeData.signatory;
      this.completedAt = safeData.completedAt;
      this.completedBy = safeData.completedBy;
      this.financialYear = safeData.financialYear;
      this.sequence = safeData.sequence;
    }

    this.createdAt = safeData.createdAt;
    this.createdBy = safeData.createdBy;
    if (safeData.updatedAt) this.updatedAt = safeData.updatedAt;
    if (safeData.updatedBy) this.updatedBy = safeData.updatedBy;
    if (safeData.hasBeenSent) this.hasBeenSent = true;

    this.calcSupportAttributes();
  }

  validate(team: Team, starting?: boolean): string[] {
    const e = super.validate();

    this.calcSupportAttributes();

    if (this.iE(this.description)) e.push('description');

    // start and end of the activity should be set consistently
    if (this.iE(this.startsAt) && !this.iE(this.endsAt)) e.push('startsAt');
    if (!this.iE(this.startsAt) && this.iE(this.endsAt)) e.push('endsAt');
    if (!this.iE(this.startsAt) && !this.iE(this.endsAt) && this.startsAt > this.endsAt) e.push('endsAt');

    this.target.validate().forEach(ea => e.push(`target.${ea}`));
    if (this.model?.options.targetType && this.target.type !== this.model.options.targetType) e.push('target.type');
    this.contacts.forEach((c, index) => {
      if (c.validate().length) e.push(`contacts[${index}]`);
    });
    this.assignees.forEach((a, index) => {
      if (a.validate().length) e.push(`assignees[${index}]`);
    });

    // if the activity is starting/started, more fields needs to be filled in
    if (starting) {
      if (!this.model) e.push('model');
      else if (this.model.options.projectIsObligatory) {
        if (!this.mainProject) e.push('mainProject');
        else this.mainProject.validate().forEach(ea => e.push(`mainProject.${ea}`));
      }
      if (this.iE(this.startsAt)) e.push('startsAt');
      if (this.iE(this.endsAt) || this.startsAt > this.endsAt) e.push('endsAt');
      if (!this.assignees.length) e.push('assignees');

      // if the activity has at least one day filled out, we need to run a complete validation
      if (this.started()) {
        this.items.forEach((i, index): void => {
          if (i.validate().length) e.push(`items[${index}]`);
        });
        this.systems.forEach((s, index): void => {
          if (s.validate().length) e.push(`systems[${index}]`);
        });

        this.model.customBlockActivity.validateSections(this.sections).forEach(es => e.push(`sections.${es}`));

        this.days.forEach((d, index) => d.validate(this.model, team).forEach(ed => e.push(`days[${index}].${ed}`)));
        if (!team.multidayReports && this.days.length > 1) e.push('days');

        if (this.model.options.suggestedFollowUp)
          this.suggestedFollowUp.validate().forEach(esf => e.push(`suggestedFollowUp.${esf}`));
      }
    }

    return e;
  }

  /**
   * Whether the activity started or not, based on the fact that there's at least one day filled out.
   */
  started(): boolean {
    return Boolean(this.days.length);
  }

  /**
   * Whether the activity is temporarily signed or signed-and-completed; in either cases it can't be changed.
   */
  isSignedOrComplete(): boolean {
    return Boolean(this.temporarySignature) || Boolean(this.completedAt);
  }
  /**
   * Whether the activity is completed (and so it can't be changed in any way) or not.
   */
  isComplete(): boolean {
    return Boolean(this.completedAt);
  }

  /**
   * Extract the ids of the projects used in the activity.
   */
  private getProjectsIds(): string[] {
    return Array.from(
      new Set(
        // the main project (if not generic)
        (this.mainProject && (this.mainProject as any).teamCustomerId ? [this.mainProject.projectId] : [])
          // the projects referred in the different days
          .concat(this.days.filter(d => d.project && (d.project as any).teamCustomerId).map(d => d.project.projectId))
      )
    );
  }
  /**
   * Extract the ids of the generic projects used in the activity.
   */
  private getGenericProjectsIds(): string[] {
    return Array.from(
      new Set(
        // the main project (if generic)
        (this.mainProject && !(this.mainProject as any).teamCustomerId ? [this.mainProject.projectId] : [])
          // the generic projects referred in the different days
          .concat(this.days.filter(d => d.project && !(d.project as any).teamCustomerId).map(d => d.project.projectId))
      )
    );
  }
  /**
   * Extract the ids of the users (memberships) involved in the activity.
   */
  private getUsersIds(): string[] {
    const usersIds = new Set<string>();
    // the assignees
    this.assignees.forEach(a => usersIds.add(a.userId));
    // the users involved in the different days
    this.days.forEach(d => d.usersDetail.forEach(u => usersIds.add(u.userId)));
    return Array.from(usersIds);
  }
  /**
   * Extract the ids of the items referred in the activity.
   */
  private getItemsIds(): string[] {
    const itemsIds = new Set<string>();
    // the items used
    this.items.forEach(i => itemsIds.add(i.itemId));
    return Array.from(itemsIds);
  }
  /**
   * Extract the ids of the systems referred in the activity.
   */
  private getSystemsIds(): string[] {
    const systemsIds = new Set<string>();
    this.systems.forEach(s => systemsIds.add(s.systemId));
    return Array.from(systemsIds);
  }
  /**
   * Extract the serial nrs. of the systems referred in the activity.
   */
  private getSystemsSerialNrs(): string[] {
    const serialNrs = new Set<string>();
    this.systems.forEach(s => serialNrs.add(s.serialNumber));
    return Array.from(serialNrs);
  }

  /**
   * Whether a user can see the activity based on permissions and the team configurations.
   */
  canUserSee(membership: Membership, team: Team): boolean {
    return canUserSeeActivity(this, membership, team);
  }
  /**
   * Whether a user can manage the activity based on permissions and team configurations.
   */
  canUserManage(membership: Membership, team: Team): boolean {
    return canUserManageActivity(this, membership, team);
  }

  /**
   * Set a new model and reload the internal attributes based on it.
   */
  changeModel(model: Model, meta: { team: Team; membership: Membership }): void {
    if (model) {
      this.modelId = model.modelId;
      this.color = model.color;
      this.model = new Model(model, meta.team);
      // set the expected behaviour for the workflow, based on the model: must the PDF report be sent?
      if (this.model.options.requireSendingReport) this.hasBeenSent = false;
      else delete this.hasBeenSent;
    } else {
      this.modelId = null;
      delete this.color;
      delete this.model;
    }
    this.load(this, meta);
  }

  /**
   * Get the state of an activity.
   */
  getState(): ActivityStates {
    if (this.isComplete() && this.hasBeenSent !== false) return ActivityStates.DONE;
    else if (this.isComplete()) return ActivityStates.TO_SEND;
    else if (this.started()) return ActivityStates.TO_COMPLETE;
    else return ActivityStates.TO_START;
  }

  /**
   * Get the total number of working hours throughout days and users.
   */
  getTotWorkingHours(): number {
    return this.days.reduce(
      (tot, day): number => tot + day.usersDetail.reduce((tot, userDetail): number => tot + userDetail.workingHours, 0),
      0
    );
  }
  /**
   * Get the total number of billed working hours throughout days and users.
   */
  getTotBilledWorkingHours(): number {
    return this.days.reduce(
      (tot, day): number =>
        tot + day.usersDetail.reduce((tot, userDetail): number => tot + userDetail.billedWorkingHours, 0),
      0
    );
  }
  /**
   * Get the total number of extra working hours throughout days and users.
   */
  getTotExtraWorkingHours(): number {
    return this.days.reduce(
      (tot, day): number =>
        tot + day.usersDetail.reduce((tot, userDetail): number => tot + userDetail.extraWorkingHours, 0),
      0
    );
  }
  /**
   * Get the total number of billed extra working hours throughout days and users.
   */
  getTotBilledExtraWorkingHours(): number {
    return this.days.reduce(
      (tot, day): number =>
        tot + day.usersDetail.reduce((tot, userDetail): number => tot + userDetail.billedExtraWorkingHours, 0),
      0
    );
  }
}

/**
 * A brief representation of an Activity.
 */
export class ActivitySummary extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * Internal or external id of the activity.
   */
  activityId: string;
  /**
   * A brief description of the activity.
   * Limited to 100 chars.
   */
  description: string;
  /**
   * The planned time when the activity will start.
   */
  startsAt?: epochDateTime;
  /**
   * The planned time when the activity will end.
   */
  endsAt?: epochDateTime;
  /**
   * Whether the scheduled activity is all-day(s) long, i.e. there is no starting/ending time.
   */
  allDay?: boolean;
  /**
   * Timestamp when a new activity has been created without being scheduled.
   * Note: `notScheduledAt` must be null if `startsAt` && `endsAt` are set, and viceversa.
   */
  notScheduledAt?: epochDateTime;
  /**
   * Summary structure representing a customer and a destination.
   */
  target: Target;
  /**
   * The users assigned in the activity.
   */
  assignees: MembershipSummary[];
  /**
   * The iCal UID (standard RFC5545) of the linked agenda appointment, if any.
   * It represents a weak link to an appointment (no data sync).
   */
  iCalUID?: string;
  /**
   * The date of completion of the activity (when it was signed).
   * When an activity is completed, it can't be changed in any way.
   */
  completedAt?: epochDateTime;
  /**
   * Financial year in which the activity was completed.
   */
  financialYear?: number;
  /**
   * Unique identifier of an activity completed within a specific financial year.
   */
  sequence?: number;
  /**
   * The id of the model used for this activity.
   */
  modelId: string;
  /**
   * The distinctive color of the model (if set) chosen for the activity.
   * RGB or HEX.
   */
  color: string;
  /**
   * The ids of the contacts involved in the activity.
   */
  contactsIds: string[];
  /**
   * The ids of the projects mentioned in the activity (during any day).
   */
  projectsIds: string[];
  /**
   * The ids of the generic projects mentioned in the activity (during any day).
   */
  genericProjectsIds: string[];
  /**
   * The ids of the teammates involved in the activity (during any day); it includes the assignees.
   */
  usersIds: string[];
  /**
   * The ids of the items used during the days.
   */
  itemsIds: string[];
  /**
   * The ids of the systems involved during the days.
   */
  systemsIds: string[];
  /**
   * The number of days currently reported in the activity.
   */
  numDays: number;
  /**
   * The date of the first day reported in the activity.
   */
  firstDay?: epochDateTime;
  /**
   * The date of the last day reported in the activity.
   */
  lastDay?: epochDateTime;
  /**
   * Whether the suggested follow-up has been set or not.
   */
  followUpSet?: boolean;
  /**
   * To indicate whether the PDF report has been sent via email after the activity completion.
   *   - If not set, the report hasn't been sent and doesn't have to (not mandatory).
   *   - If set and false, the report hasn't been sent yet, but it has to (mandatory).
   *   - If set and true, the report has been sent at least once after the activity was completed.
   */
  hasBeenSent?: boolean;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.activityId = this.clean(x.activityId, String);
    this.description = this.clean(x.description, s => String(s).slice(0, 100));
    if (x.startsAt) this.startsAt = this.clean(x.startsAt, d => new Date(d).getTime()) as epochDateTime;
    if (x.endsAt) this.endsAt = this.clean(x.endsAt, d => new Date(d).getTime()) as epochDateTime;
    if (x.allDay !== undefined) this.allDay = this.clean(x.allDay, Boolean);
    if (x.notScheduledAt)
      this.notScheduledAt = this.clean(x.notScheduledAt, d => new Date(d).getTime()) as epochDateTime;
    this.target = new Target(x.target);
    this.assignees = this.cleanArray(x.assignees, u => new MembershipSummary(u));
    if (x.iCalUID) this.iCalUID = this.clean(x.iCalUID, String);
    if (x.completedAt) {
      this.completedAt = this.clean(x.completedAt, d => new Date(d).getTime()) as epochDateTime;
      this.financialYear = this.clean(x.financialYear, Number);
      this.sequence = this.clean(x.sequence, Number);
    }
    this.modelId = this.clean(x.modelId, String);
    this.color = this.clean(x.color, String);
    this.contactsIds = this.cleanArray(x.contactsIds, String);
    this.projectsIds = this.cleanArray(x.projectsIds, String);
    this.genericProjectsIds = this.cleanArray(x.genericProjectsIds, String);
    this.usersIds = this.cleanArray(x.usersIds, String);
    this.itemsIds = this.cleanArray(x.itemsIds, String);
    this.systemsIds = this.cleanArray(x.systemsIds, String);
    this.numDays = this.clean(x.numDays, Number);
    if (x.firstDay) this.firstDay = this.clean(x.firstDay, d => new Date(d).getTime()) as epochDateTime;
    if (x.lastDay) this.lastDay = this.clean(x.lastDay, d => new Date(d).getTime()) as epochDateTime;
    if (x.followUpSet) this.followUpSet = true;
    if (x.hasBeenSent !== undefined) this.hasBeenSent = this.clean(x.hasBeenSent, Boolean);
  }

  /**
   * Whether the activity started or not, based on the fact that there's at least one day filled out.
   */
  started(): boolean {
    return Boolean(this.numDays);
  }

  /**
   * Whether the activity is completed (and so it can't be changed in any way) or not.
   */
  isComplete(): boolean {
    return Boolean(this.completedAt);
  }

  /**
   * Whether a user can see the activity based on permissions and the team configurations.
   */
  canUserSee(membership: Membership, team: Team): boolean {
    return canUserSeeActivity(this, membership, team);
  }
  /**
   * Whether a user can manage the activity based on permissions and the team configurations.
   */
  canUserManage(membership: Membership, team: Team): boolean {
    return canUserManageActivity(this, membership, team);
  }

  /**
   * Get the state of an activity.
   */
  getState(): ActivityStates {
    if (this.isComplete() && this.hasBeenSent !== false) return ActivityStates.DONE;
    else if (this.isComplete()) return ActivityStates.TO_SEND;
    else if (this.started()) return ActivityStates.TO_COMPLETE;
    else return ActivityStates.TO_START;
  }
}

/**
 * A support structure containing info on the targeted customer and destination.
 */
export class Target extends Resource {
  /**
   * The id of the customer.
   */
  customerId: string;
  /**
   * The id of the destination.
   */
  destinationId: string;
  /**
   * The name of the target.
   */
  name: string;
  /**
   * The office of the target.
   */
  office: string;
  /**
   * The address of the target.
   */
  address: string;
  /**
   * The geolocation of the target (based on the address).
   */
  geolocation?: AddressGeolocation;
  /**
   * The distance in km from the team's office.
   */
  distanceKm: number;
  /**
   * The distance in hours from the team's office.
   */
  distanceHours: number;
  /**
   * Notes on the target.
   */
  notes: string;
  /**
   * Email to contact the target.
   */
  email: string;
  /**
   * The preferred language of the target.
   */
  language: string;
  /**
   * Whether the activity will be executed remotely, i.e. not on-site.
   */
  remote: boolean;
  /**
   * The type of customer.
   */
  type: CustomerTypes;

  load(x: any): void {
    super.load(x);
    this.customerId = this.clean(x.customerId, String);
    this.destinationId = this.clean(x.destinationId, String);
    this.name = this.clean(x.name, String);
    this.office = this.clean(x.office, String);
    this.address = this.clean(x.address, String);
    if (x.geolocation) this.geolocation = new AddressGeolocation(x.geolocation);
    this.notes = this.clean(x.notes, String);
    this.distanceKm = this.clean(x.distanceKm, Number, 0);
    this.distanceHours = this.clean(x.distanceHours, Number, 0);
    this.notes = this.clean(x.notes, String);
    this.email = this.clean(x.email, String);
    this.language = this.clean(x.language, String);
    this.remote = this.clean(x.remote, Boolean);
    this.type = this.clean(x.type, String, CustomerTypes.CUSTOMER);
  }

  /**
   * Load the target's data with the info form customer and destination.
   */
  loadFromCustomerAndDestination(customer: Customer, destination: Destination): void {
    this.customerId = this.clean(customer.customerId, String);
    this.destinationId = this.clean(destination.destinationId, String);
    this.name = this.clean(customer.businessName, String);
    this.office = this.clean(destination.name, String);
    this.address = this.clean(destination.address.getFullAddress(), String);
    if (destination.address.geolocation) this.geolocation = new AddressGeolocation(destination.address.geolocation);
    this.email = this.clean(destination.email, String) || this.clean(customer.email, String);
    this.distanceKm = this.clean(destination.distanceKm, Number);
    this.distanceHours = this.clean(destination.distanceHours, Number);
    this.notes = this.clean(destination.notes, String);
    this.language = this.clean(destination.language, String);
    this.type = this.clean(customer.type, String, CustomerTypes.CUSTOMER);
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.customerId)) e.push('customerId');
    if (this.iE(this.destinationId)) e.push('destinationId');
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.office)) e.push('office');
    return e;
  }
}

/**
 * The report of a day of a specific activity.
 */
export class ActivityDay extends Resource {
  /**
   * The date of when this part of the activity has taken place; initialized as today.
   */
  date: epochDateTime;
  /**
   * The description of the day.
   * Can be hidden by `team.hideDailyReportDescription`.
   * Limit to 1000 chars.
   */
  description: markdown;
  /**
   * The project for for which this day is executed.
   */
  project: GenericProjectSummary | ProjectSummary;
  /**
   * The detail of each user involved in this day.
   */
  usersDetail: ActivityDayUserDetail[];
  /**
   * The custom sections and fields of this days, managed by `model.customBlockActivityDay`.
   */
  sections: any;

  load(x: any, model: Model): void {
    super.load(x);
    this.date = this.clean(x.date, d => new Date(d).getTime(), Date.now()) as epochDateTime;
    this.description = this.clean(x.description, s => String(s).slice(0, 1000));
    if (x.project) {
      if (x.project.teamCustomerId) this.project = this.clean(x.project, p => new ProjectSummary(p));
      else this.project = this.clean(x.project, p => new GenericProjectSummary(p));
    }
    this.usersDetail = this.cleanArray(x.usersDetail, u => new ActivityDayUserDetail(u, model));
    if (model && model.customBlockActivityDay) {
      if (x.sections) this.sections = model.customBlockActivityDay.loadSections(x.sections);
      else this.sections = model.customBlockActivityDay.setSectionsDefaultValues();
    }
  }

  validate(model: Model, team?: Team): string[] {
    const e = super.validate();
    if (this.iE(this.date)) e.push('date');
    if (
      (!team.multidayReports || (team.multidayReports && !team.hideDailyReportDescription)) &&
      this.iE(this.description)
    )
      e.push('description');
    if (!this.usersDetail.length) e.push('usersDetail');
    else this.usersDetail.forEach((u, i): void => u.validate(team).forEach(ea => e.push(`usersDetail[${i}].${ea}`)));
    if (!model) e.push('model');
    else {
      if (model.options.projectIsObligatory) {
        if (!this.project) e.push('project');
        else this.project.validate().forEach(ea => e.push(`project.${ea}`));
      }
      if (team.multidayReports)
        model.customBlockActivityDay.validateSections(this.sections).forEach(es => e.push(`sections.${es}`));
    }
    return e;
  }
}

/**
 * The detail of a specific user of an activity's day.
 */
export class ActivityDayUserDetail extends Resource {
  /**
   * The id of the user.
   */
  userId: string;
  /**
   * The name of the user.
   */
  name: string;
  /**
   * If the model enables it, the time intervals (from-to) worked during the specified day.
   */
  timeIntervalsWorked?: TimeInterval[];
  /**
   * The workingHours of the user in the day.
   */
  workingHours: number;
  /**
   * The extraWorkingHours of the user in the day
   */
  extraWorkingHours: number;
  /**
   * The billedWorkingHours of the user in the day.
   */
  billedWorkingHours: number;
  /**
   * The billedExtraWorkingHours of the user in the day.
   */
  billedExtraWorkingHours: number;
  /**
   * The travelling km of the user in the day.
   */
  travellingKm: number;
  /**
   * The travelling hours of the user in the day.
   */
  travellingHours: number;
  /**
   * The internal travelling hours of the user in the day; possibly to use for HR purposes.
   */
  internalTravellingHours: number;
  /**
   * The vehicle used by the user to reach the target.
   * `null` = none used.
   */
  vehicle: string;
  /**
   * The id of the Horace track.
   * If set but null it means an insert was tried but it failed.
   */
  horaceId?: string;

  load(x: any, model: Model): void {
    super.load(x);
    this.userId = this.clean(x.userId, String);
    this.name = this.clean(x.name, String);
    if (model.options.hoursFromToPicker) {
      this.timeIntervalsWorked = this.cleanArray(x.timeIntervalsWorked, t => new TimeInterval(t));
      // backwards-compatibility from obsolete fields: morningFromTo, afternoonFromTo (#448)
      // @todo data transfer: the fields above should be converted for activities created prior #448
      if (x.morningFromTo) this.timeIntervalsWorked.push(new TimeInterval(x.morningFromTo));
      if (x.afternoonFromTo) this.timeIntervalsWorked.push(new TimeInterval(x.afternoonFromTo));
      // remove empty intervals, then make sure there is always an empty one at the end of the list
      this.timeIntervalsWorked = this.timeIntervalsWorked.filter(t => t.isSet());
      this.timeIntervalsWorked.push(new TimeInterval());
      this.workingHours = this.getWorkingHoursFromTimeIntervals();
    } else this.workingHours = this.clean(x.workingHours, Number, 0);
    this.workingHours = this.clean(x.workingHours, Number, 0);
    this.extraWorkingHours = this.clean(x.extraWorkingHours, Number, 0);
    this.billedWorkingHours = this.clean(x.billedWorkingHours, Number, 0);
    this.billedExtraWorkingHours = this.clean(x.billedExtraWorkingHours, Number, 0);
    this.travellingKm = this.clean(x.travellingKm, Number, 0);
    this.travellingHours = this.clean(x.travellingHours, Number, 0);
    this.internalTravellingHours = this.clean(x.internalTravellingHours, Number, 0);
    this.vehicle = this.clean(x.vehicle, String);
    if (x.horaceId !== undefined) this.horaceId = this.clean(x.horaceId, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.userId = safeData.userId;
    if (safeData.horaceId !== undefined) this.horaceId = safeData.horaceId;
  }

  validate(team: Team): string[] {
    const e = super.validate();
    if (this.iE(this.userId)) e.push('userId');
    if (this.iE(this.name)) e.push('name');

    if (this.timeIntervalsWorked?.length)
      this.validateTimeIntervals(this.timeIntervalsWorked).forEach(i => e.push(`timeIntervalsWorked[${i}]`));

    if (this.vehicle && !team.vehicles.some(v => v === this.vehicle)) e.push('vehicle');
    return e;
  }
  /**
   * Validate the consistency of a series of time intervals and return the indexes of the inconstent ones.
   * Note: unset intervals are ignored.
   */
  private validateTimeIntervals(timeIntervals: TimeInterval[]): number[] {
    const indexesOfInvalidTimeIntervals: number[] = [];

    timeIntervals
      .filter(el => el.isSet())
      .forEach((el, i, arr) => {
        const isFirst = i === 0;
        const isLast = i === arr.length - 1;
        const prevIsBigger = !isFirst && el.from < arr[i - 1].to;
        const nextIsSmaller = !isLast && el.to > arr[i + 1].from;

        if (el.validate().length || prevIsBigger || nextIsSmaller) indexesOfInvalidTimeIntervals.push(i);
      });

    return indexesOfInvalidTimeIntervals;
  }

  /**
   * Get the total time worked in the time intervals during the day.
   */
  getWorkingDurationFromTimeIntervals(): number {
    if (!this.timeIntervalsWorked?.length) return 0;
    return this.timeIntervalsWorked.reduce((tot, el): number => (tot += el.getDuration()), 0);
  }
  /**
   * Get the hours worked, calculated (and rounded) from the time intervals (morning and afternoon).
   */
  getWorkingHoursFromTimeIntervals(round?: boolean): number {
    const hours = this.getWorkingDurationFromTimeIntervals() / 1000 / 60 / 60;
    return round ? Math.round(hours) : hours;
  }
}

/**
 * The suggested follow-up of an activity.
 */
export class SuggestedFollowUp extends Resource {
  /**
   * The description of the suggested follow up.
   * Limit to 500 chars.
   */
  description: string;
  /**
   * The internal notes of the suggested follow up. It's used for internal scopes and not shared outside the team.
   * Limit to 500 chars.
   */
  notes: string;
  /**
   * The list of suggested items to use/buy/consider/etc..
   */
  items: ItemUsed[];

  load(x: any): void {
    super.load(x);
    this.description = this.clean(x.description, s => String(s).slice(0, 500));
    this.notes = this.clean(x.notes, s => String(s).slice(0, 500));
    this.items = this.cleanArray(x.items, i => new ItemUsed(i));
  }

  validate(): string[] {
    const e = super.validate();
    this.items.forEach((i, index): void => {
      if (i.validate().length) e.push(`items[${index}]`);
    });
    return e;
  }

  /**
   * Whether the follow-up has been set.
   */
  isSet(): boolean {
    return Boolean(this.description || this.items.length || this.notes);
  }
  /**
   * Whether the follow-up has been set and should be visible in reports for the target.
   */
  isSetAndExternal(): boolean {
    return Boolean(this.description || this.items.length);
  }
}

/**
 * The possible states of the activities.
 */
export enum ActivityStates {
  TO_START = 'TO_START',
  TO_COMPLETE = 'TO_COMPLETE',
  TO_SEND = 'TO_SEND',
  DONE = 'DONE'
}

/**
 * Whether a user can see the activity based on permissions and the team configurations.
 */
const canUserSeeActivity = (activity: Activity | ActivitySummary, membership: Membership, team: Team): boolean => {
  // the user can see if
  return (
    // the user is an assignee or is involved (depending on the team's configuration) in the activity
    (team.activityPermissionsAreBasedOnInvolvement
      ? activity.usersIds.includes(membership.userId)
      : activity.assignees.some(a => a.userId === membership.userId)) ||
    // or if the activity is unassigned and we have the permission
    (!activity.assignees.length && membership.permissions.activities.canSeeUnassigned) ||
    // or if the activity is assigned to others and we have the permission
    membership.permissions.activities.canSeeOfOthers
  );
};
/**
 * Whether a user can manage the activity based on permissions and the team configurations.
 */
const canUserManageActivity = (activity: Activity | ActivitySummary, membership: Membership, team: Team): boolean => {
  // the user can manage if
  return (
    // the user is an assignee or is involved (depending on the team's configuration) in the activity
    (team.activityPermissionsAreBasedOnInvolvement
      ? activity.usersIds.includes(membership.userId)
      : activity.assignees.some(a => a.userId === membership.userId)) ||
    // it has manager permissions on the activities
    membership.permissions.activities.canManage
  );
};
