import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { IDEACachedResourcesService, IDEATinCanService, IDEATranslationsService } from '@idea-ionic/common';

import { ActivityStates } from '@models/activity.model';

/**
 * The base URLs where the thumbnails are located.
 */
export const THUMBNAILS_BASE_URL = 'https://s3.eu-west-2.amazonaws.com/scarlett-media/thumbnails/';
/**
 * The base URLs where the users avatars are located.
 */
export const USERS_AVATARS_BASE_URL = THUMBNAILS_BASE_URL.concat('avatars/');
/**
 * A local fallback URL for the users signatures.
 */
export const USERS_SIGNATURE_FALLBACK_URL = './assets/imgs/no-signature.png';
/**
 * The URL to the avatar for unassigned entitites.
 */
export const UNASSIGNED_AVATAR = './assets/imgs/unassigned.jpg';
/**
 * The base URLs where the thumbnails are located.
 */
export const TEAMS_LOGOS_BASE_URL = THUMBNAILS_BASE_URL.concat('teamLogos/');
/**
 * A local fallback URL for the logos.
 */
export const TEAMS_LOGOS_FALLBACK_URL = './assets/imgs/no-logo.png';

/**
 * Utilities for Scarlett.
 */
@Injectable()
export class ScarlettService {
  constructor(
    protected platform: Platform,
    protected alertCtrl: AlertController,
    protected tc: IDEATinCanService,
    protected crs: IDEACachedResourcesService,
    protected t: IDEATranslationsService
  ) {}

  /**
   * Whether we should display a UX designed for smaller screens.
   */
  public isInMobileMode(): boolean {
    return this.platform.width() < 768;
  }

  /**
   * Get the URL to a user's avatar in the current team.
   */
  public getUserAvatar(userId?: string, refresh?: boolean): string {
    const teamId = this.tc.get('membership').teamId;
    userId = userId || this.tc.get('membership').userId;
    return this.crs.getCachedResource(USERS_AVATARS_BASE_URL.concat(teamId, '/', userId, '.png'), refresh);
  }

  /**
   * Load a fallback URL when the user's signature is missing.
   */
  public fallbackUserSignature(targetImg: any) {
    if (targetImg && targetImg.src !== USERS_SIGNATURE_FALLBACK_URL) targetImg.src = USERS_SIGNATURE_FALLBACK_URL;
  }

  /**
   * Get the URL to the team's logo.
   */
  public getTeamLogo(): string {
    const teamId = this.tc.get('membership').teamId;
    return this.crs.getCachedResource(TEAMS_LOGOS_BASE_URL.concat(teamId, '.png'));
  }
  /**
   * Get the URL to the model's logo.
   */
  public getModelLogo(modelId: string): string {
    const teamId = this.tc.get('membership').teamId;
    return this.crs.getCachedResource(THUMBNAILS_BASE_URL.concat('modelLogos/', teamId, '/', modelId, '.png'));
  }
  /**
   * Load a fallback URL when the logo is missing. First tries the team's logo, then a local fallback asset.
   */
  public fallbackLogo(targetImg: any) {
    const teamLogo = this.getTeamLogo();
    if (targetImg && targetImg.src !== teamLogo) targetImg.src = teamLogo;
    else if (targetImg && targetImg.src === teamLogo) targetImg.src = TEAMS_LOGOS_FALLBACK_URL;
  }

  /**
   * Get an icon (ion-icon and title) based on an activity's state.
   */
  public getActivityIconByState(state: ActivityStates | string): { name: string; title: string } {
    switch (state) {
      case ActivityStates.TO_START:
        return { name: 'egg', title: this.t._('ACTIVITY.STATES.TO_START') };
      case ActivityStates.TO_COMPLETE:
        return { name: 'document', title: this.t._('ACTIVITY.STATES.TO_COMPLETE') };
      case ActivityStates.TO_SEND:
        return { name: 'send', title: this.t._('ACTIVITY.STATES.TO_SEND') };
      case ActivityStates.DONE:
        return { name: 'checkmark-done', title: this.t._('ACTIVITY.STATES.DONE') };
      default:
        return { name: 'help', title: '' };
    }
  }

  /**
   * Open the description of the chosen field, given the field name and the description.
   */
  public showHint(title: string, description: string, event?: any) {
    if (event) event.stopPropagation();
    if (!description) return;
    this.alertCtrl
      .create({ header: title, message: description, buttons: ['OK'], cssClass: 'alertLongOptions' })
      .then(alert => alert.present());
  }

  /**
   * Reload the app.
   */
  public reloadApp() {
    window.location.assign('');
  }
}
