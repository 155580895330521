/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'scarlett',
    ionicExtraModules: ['common', 'variables', 'auth', 'teams', 'agenda'],
    app: {
      version: '1.19.7',
      bundle: 'com.iteridea.scarlett',
      url: 'https://scarlett.iter-idea.com',
      appleStoreURL: 'https://apps.apple.com/us/app/scarlett/id1412551432',
      googleStoreURL: 'https://play.google.com/store/apps/details?id=com.iteridea.scarlett'
    },
    api: { url: 'api-scarlett.iter-idea.com', version: 'prod' },
    ideaApi: { url: 'api.iter-idea.com', version: 'prod' },
    auth: {
      title: 'Scarlett',
      hasIntroPage: true,
      website: 'https://iter-idea.com',
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      passwordPolicy: {
        minLength: 8,
        requireLowercase: false,
        requireDigits: false,
        requireSymbols: false,
        requireUppercase: false
      }
    }
  },
  aws: {
    cognito: { userPoolId: 'eu-west-2_vbuZa7Ydg', userPoolClientId: '58rm82qmou37chghkpd0rv0bak' }
  },
  microsoft: {
    apiClientId: '1dcb2e26-b402-4085-a25a-61054687b0e4',
    apiScope: 'offline_access user.read Calendars.ReadWrite Calendars.ReadWrite.Shared'
  },
  google: {
    apiClientId: '121599012131-b7hndachsohn5u3j2apjnoqne3uglv8e.apps.googleusercontent.com',
    apiScope:
      // eslint-disable-next-line max-len
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar',
    mapsApiKey: 'AIzaSyAOJs9LMizW9da88Q9qCFKpVn5lTTJVC2k'
  }
};
