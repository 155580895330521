import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { Announcement } from 'idea-toolbox';
import {
  IDEATinCanService,
  IDEATranslationsService,
  IDEAAWSAPIService,
  CacheModes,
  IDEAStorageService
} from '@idea-ionic/common';

import { environment as env } from '@env';
import { ServiceLanguages } from '@models/serviceLanguages.enum';

export const initGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const storage = inject(IDEAStorageService);
  const navCtrl = inject(NavController);
  const t = inject(IDEATranslationsService);
  const tc = inject(IDEATinCanService);
  const api = inject(IDEAAWSAPIService);

  //
  // HELPERS
  //

  const implementAndroidBackButton = (): void => {
    // implement Android physical back button (low priority so that other Ionic's features come first)
    platform.backButton.subscribeWithPriority(0, (): void => {
      // determ the homepage, based on the fact we are logged in or not
      const homepage = tc.get('membership') ? `/teams/${String(tc.get('membership').teamId)}` : '/auth/sign-in';
      // try to go back; note: the location is updated only to the previuous "back" performed
      window.history.back();
      // if while going back we go further the homepage, exit the app to please Veronica
      if (window.location.pathname === homepage && navigator && (navigator as any)['app'])
        (navigator as any)['app'].exitApp();
    });
  };

  const listenForDarkLightModeChanges = (): void => {
    tc.set('darkMode', window.matchMedia('(prefers-color-scheme: dark)').matches);
    window
      .matchMedia('(prefers-color-scheme: dark)')
      // tslint:disable-next-line: deprecation
      .addListener(mediaQuery => tc.set('darkMode', mediaQuery.matches));
  };

  const checkForAnnouncementsOrSetMaintenanceMode = async (): Promise<void> => {
    // check if there is any announcement for this project
    const announcement: Announcement = await api.getResource('announcements', {
      idea: true,
      resourceId: env.idea.project,
      useCache: CacheModes.NETWORK_FIRST
    });
    // if there is, set the content (it will be read by either the AnnouncementComponent or the EchoPage)
    if (announcement && announcement.content) {
      tc.set('idea-announcement', new Announcement(announcement));
      // if the announcment is of maintenance, navigate to Echo to display it
      if (announcement.maintenance) {
        // force the maintenance mode and block the normal use
        navCtrl.navigateRoot(['echo', 'maintenance']);
        tc.set('ready', true);
      }
    }
  };

  //
  // MAIN
  //

  await platform.ready();
  await storage.ready();

  // check whether the app was already initialized
  if (tc.get('init')) return true;

  listenForDarkLightModeChanges();

  await t.init(Object.values(ServiceLanguages), ServiceLanguages.English);
  let lang: ServiceLanguages = t.getBrowserLang() as ServiceLanguages;
  if (!Object.values(ServiceLanguages).includes(lang)) lang = t.getDefaultLang() as ServiceLanguages;
  await t.use(lang, true);

  implementAndroidBackButton();

  await checkForAnnouncementsOrSetMaintenanceMode();

  tc.set('init', true);
  return true;
};
