import { EventNotificationPreference, MembershipSummary, PushNotificationsDevice, Resource } from 'idea-toolbox';

/**
 * Table: `scarlett_teams_users`.
 *
 * Indexes:
 *    - `teamId-name-index`; includes: initials, permissions, calendar, emails. (LSI)
 *    - `userId-index`. (GSI, keys)
 */
export class Membership extends Resource {
  /**
   * The id of the team.
   */
  teamId: string;
  /**
   * The id of the user.
   */
  userId: string;
  /**
   * The name of the user on this membership.
   */
  name: string;
  /**
   * A short representation of the name, through initials.
   * They should be unique across the team.
   */
  initials: string;
  /**
   * The permissions of the user on the team.
   */
  permissions: MembershipPermissions;
  /**
   * The ids of the items that are currently on the user.
   */
  mobileWarehouse: string[];
  /**
   * The user's notifications preferences in the team.
   */
  notificationPreferences: NotificationPreferences;
  /**
   * The user's push devices registered, for notifications.
   */
  pushDevices: PushNotificationsDevice[];
  /**
   * The external calendar linked to this membership, if any.
   */
  calendar?: MembershipLinkedCalendar;
  /**
   * List of known emails of the user. It is build over time while the user links services or changes email addresses.
   * Conventionally, the first email is the one currently used to login (Cognito).
   */
  emails: string[];

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.userId = this.clean(x.userId, String);
    this.name = this.clean(x.name, String);
    this.initials = this.clean(x.initials, String);
    if (!this.initials) this.initials = this.getInitials();
    this.permissions = new MembershipPermissions(x.permissions);
    this.mobileWarehouse = this.cleanArray(x.mobileWarehouse, String);
    this.notificationPreferences = new NotificationPreferences(x.notificationPreferences);
    this.pushDevices = this.cleanArray(x.pushDevices, d => new PushNotificationsDevice(d));
    if (x.calendar) this.calendar = new MembershipLinkedCalendar(x.calendar);
    this.emails = this.cleanArray(x.emails, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.userId = safeData.userId;
    this.name = safeData.name;
    this.initials = safeData.initials;
    this.permissions = safeData.permissions;
    this.mobileWarehouse = safeData.mobileWarehouse;
    if (safeData.calendar) this.calendar = safeData.calendar;
    this.emails = safeData.emails;
  }

  /**
   * Get the initials from the name, optionally concatenating a counter to fix univocity (if `memberships` is set).
   */
  getInitials(memberships?: MembershipSummary[], retry?: number): string {
    if (!this.name) return null;
    // get the initials from the name
    let initials = this.name
      .split(' ')
      .map(w => w.slice(0, 1))
      .join('')
      .toUpperCase();
    // if the current initials already exist ("retry scenario"), retry by concatenating a counter after them
    retry = retry || 1;
    if (retry > 1) initials += String(retry);
    // if requested, check whether the initials are unique inside the team
    if (!memberships || !memberships.length || this.areInitialsUnique(memberships, initials)) return initials;
    // if the initials aren't unique, retry with a counter as suffix
    else return this.getInitials(memberships, ++retry);
  }
  /**
   * Whether the initials provided are unique inside the team, based on the other memberships.
   */
  areInitialsUnique(memberships: MembershipSummary[], initials?: string): boolean {
    initials = initials || this.initials;
    // check against other memberships
    return !memberships || !memberships.filter(m => m.userId !== this.userId).some(m => m.initials === initials);
  }
}

/**
 * What a specific membership ('you') can do over the service entities.
 */
export class MembershipPermissions {
  /**
   * Permissions on the activities.
   */
  activities: ActivityPermissions;
  /**
   * Permissions on the customers.
   */
  customers: BasicEntityPermissions;
  /**
   * Permissions on the customers' destinations.
   */
  destinations: BasicEntityPermissions;
  /**
   * Permissions on the customers' contacts.
   */
  contacts: BasicEntityPermissions;
  /**
   * Permissions on the customers' projects.
   */
  projects: BasicEntityPermissions;
  /**
   * Permissions on the items.
   */
  items: BasicEntityPermissions;
  /**
   * Permissions on the systems.
   */
  systems: BasicEntityPermissions;
  /**
   * Permissions on the generic projects.
   */
  genericProjects: BasicEntityPermissions;
  /**
   * Whether you can see advanced filters in the archive page.
   */
  canSeeArchiveAdvancedFilters: boolean;
  /**
   * Whether you are an administrator (full permissions) or not.
   * As administrator, you can implicitely edit the team and the models.
   */
  protected _admin: boolean;
  get admin(): boolean {
    return this._admin;
  }
  set admin(isAdmin: boolean) {
    this._admin = isAdmin;
    if (isAdmin) {
      this.activities.canManage = true;
      this.customers.canManage = true;
      this.destinations.canManage = true;
      this.contacts.canManage = true;
      this.projects.canManage = true;
      this.items.canManage = true;
      this.systems.canManage = true;
      this.genericProjects.canManage = true;
      this.canSeeArchiveAdvancedFilters = true;
    }
  }

  constructor(x?: any) {
    x = x || {};
    this.activities = new ActivityPermissions(x.activities);
    this.customers = new BasicEntityPermissions(x.customers);
    this.destinations = new BasicEntityPermissions(x.destinations);
    this.contacts = new BasicEntityPermissions(x.contacts);
    this.projects = new BasicEntityPermissions(x.projects);
    this.items = new BasicEntityPermissions(x.items);
    this.systems = new BasicEntityPermissions(x.systems);
    this.genericProjects = new BasicEntityPermissions(x.genericProjects);
    this.canSeeArchiveAdvancedFilters = Boolean(x.canSeeArchiveAdvancedFilters);
    // as last, to change any other attribute in case it's `true`
    this.admin = Boolean(x._admin);
  }
}

/**
 * Basic permissions on an entity.
 */
export class BasicEntityPermissions {
  /**
   * Whether you can access the entity's details.
   */
  canAccessDetail: boolean;
  /**
   * Whether you can manage the entity.
   * If this is true, all other permissions are.
   */
  protected _canManage: boolean;
  get canManage(): boolean {
    return this._canManage;
  }
  set canManage(canManage: boolean) {
    this._canManage = canManage;
    if (canManage) {
      this.canAccessDetail = true;
    }
  }

  constructor(x?: any) {
    x = x || {};
    this.canAccessDetail = Boolean(x.canAccessDetail);
    // as last, to change any other attribute in case it's `true`
    this.canManage = Boolean(x._canManage);
  }
}

/**
 * Specific permissions for the Activities.
 * Notes:
 *  - you can always access the activities you're assigned to and you can edit almost all the fields,
 *    excluding the ones that have specific permissions.
 *  - to access (see in the list and the details) activities unassigned/assigned to others, you need either
 *    `canSeeOfOthers` or `canSeeOfOthers`; in any case, you can't edit those without `canManage`.
 *  - to create new activities or to manage activities unassigned/assigned to others, you need `canManage`.
 */
export class ActivityPermissions {
  /**
   * Whether you can see and access the activities assigned to others (not to you).
   */
  canSeeOfOthers: boolean;
  /**
   * Whether you can see and access the activities not assigned (unassigned).
   */
  canSeeUnassigned: boolean;

  //
  // FIELDS-SPECIFIC PERMISSIONS
  //

  /**
   * Whether you can insert new activities.
   */
  canInsert: boolean;
  /**
   * Whether you can edit the model of an activity.
   */
  canEditModel: boolean;
  /**
   * Whether you can edit the dates of an activity.
   */
  canEditDates: boolean;
  /**
   * Whether you can edit the target (customer & destination) of an activity.
   */
  canEditTarget: boolean;
  /**
   * Whether you can edit project of an activity.
   */
  canEditProject: boolean;
  /**
   * Whether you can edit the assignees of an activity.
   */
  canEditAssignees: boolean;
  /**
   * Whether you can edit the descriptions (titles, notes, etc.) of an activity.
   */
  canEditDescriptions: boolean;
  /**
   * Whether you can fully manage the activities (both yours and the ones of your teammates).
   * If this is true, all other permissions are.
   */
  protected _canManage: boolean;
  get canManage(): boolean {
    return this._canManage;
  }
  set canManage(canManage: boolean) {
    this._canManage = canManage;
    if (canManage) {
      this.canSeeOfOthers = true;
      this.canSeeUnassigned = true;
      this.canInsert = true;
      this.canEditModel = true;
      this.canEditDates = true;
      this.canEditTarget = true;
      this.canEditProject = true;
      this.canEditAssignees = true;
      this.canEditDescriptions = true;
    }
  }

  constructor(x?: any) {
    x = x || {};
    this.canSeeOfOthers = Boolean(x.canSeeOfOthers);
    this.canSeeUnassigned = Boolean(x.canSeeUnassigned);
    this.canInsert = Boolean(x.canInsert);
    this.canEditModel = Boolean(x.canEditModel);
    this.canEditDates = Boolean(x.canEditDates);
    this.canEditTarget = Boolean(x.canEditTarget);
    this.canEditProject = Boolean(x.canEditProject);
    this.canEditAssignees = Boolean(x.canEditAssignees);
    this.canEditDescriptions = Boolean(x.canEditDescriptions);
    // as last, to change any other attribute in case it's `true`
    this.canManage = Boolean(x._canManage);
  }
}

/**
 * The reference to the calendar linked to a membership.
 */
export class MembershipLinkedCalendar extends Resource {
  /**
   * The id of the (IDEA) calendar.
   */
  calendarId: string;
  /**
   * The email associated to the calendar (often the sign-in email of the external service).
   */
  email: string;

  load(x: any): void {
    super.load(x);
    this.calendarId = this.clean(x.calendarId, String);
    this.email = this.clean(x.email, String);
  }
}

/**
 * The notification preferences available for a membership.
 */
export class NotificationPreferences implements NotificationPreferences {
  /**
   * The map of events with their notification preferences.
   */
  [event: string]: EventNotificationPreference;
  /**
   * The notification preference after the user has been assigned or removed from an activity.
   */
  activityAssigneeChange: EventNotificationPreference;

  constructor(x: any = {}) {
    this.activityAssigneeChange = new EventNotificationPreference(x.activityAssigneeChange);
  }
}
