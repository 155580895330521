import { environment as defaultEnv } from './environment.idea';

// @idea note well: "environment.prod.ts" !== "AWS prod". Explanation:
// When developing locally (no matter the scenario), the default environment file is used (`environment.ts`).
// This file is used when the app is deployed to the cloud, no matter if in the "dev" or "prod" AWS environment.
export const environment = Object.assign({}, defaultEnv, {
  debug: false,
  google: {
    apiClientId: '121599012131-b7hndachsohn5u3j2apjnoqne3uglv8e.apps.googleusercontent.com',
    apiScope:
      // eslint-disable-next-line max-len
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar',
    mapsApiKey: 'AIzaSyDWFG2REL2VE0jwL-l5a8UVWYcbAC-4Kio'
  }
});
