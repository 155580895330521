import {
  CustomBlockMeta,
  EmailData,
  epochDateTime,
  Label,
  Languages,
  MembershipSummary,
  PDFTemplateSection,
  RCConfiguredFolder,
  Resource
} from 'idea-toolbox';
import { CustomerTypes } from './customer.model';

import { Team } from './team.model';

/**
 * Table: `scarlett_teams_models`.
 *
 * Indexes:
 *    - `teamId-color-index`; includes: name. (LSI)
 */
export class Model extends Resource {
  /**
   * The id of the team owner of the model.
   */
  teamId: string;
  /**
   * Internal or external id.
   */
  modelId: string;
  /**
   * The name of the model. Support multilanguage.
   */
  name: Label;
  /**
   * Fixed fields options.
   */
  options: ModelOptions;
  /**
   * In case items are allowed, the labels of the custom checkboxes to display (based on `options.itemsCheckboxesNum`).
   */
  itemsCheckboxes?: Label[];
  /**
   * If `options.resourceCenter` is true, the folder from which resources can be taken to be attached to the report.
   */
  resourceCenterFolder?: RCConfiguredFolder;
  /**
   * A custom block containing custom sections and fields at the activity level.
   */
  customBlockActivity: CustomBlockMeta;
  /**
   * If `team.multidayReports` is set, a custom block containing custom sections and fields for each activity's day.
   */
  customBlockActivityDay?: CustomBlockMeta;
  /**
   * Primary color for the model.
   */
  color: string;
  /**
   * Default values for email sending.
   */
  emailDefaults: EmailData;
  /**
   * Default for the report description.
   */
  descriptionDefault: string;
  /**
   * The suggested follow-up of the report; note: it is managed only when `options.suggestedFollowUp` is true.
   */
  suggestedFollowUp: SuggestedFollowUpConfiguration;
  /**
   * The options for the PDF report of the activities of this model.
   */
  pdf: ModelPDFOptions;
  /**
   * If set, the endpoint where to publish the activity and the report PDF.
   */
  webhookURL: string;
  /**
   * If `options.horace` is true, the specifications to map each ReportUser into a track.
   */
  horaceConfig?: HoraceConfig;
  /**
   * Timestamp of creation.
   */
  createdAt: epochDateTime;
  /**
   * The user who created the model.
   */
  createdBy: MembershipSummary;
  /**
   * Timestamp of last update.
   */
  updatedAt?: epochDateTime;
  /**
   * The user who lastly updated the model.
   */
  updatedBy?: MembershipSummary;

  load(x: any, team: Team): void {
    super.load(x, team);
    this.teamId = this.clean(x.teamId, String);
    this.modelId = this.clean(x.modelId, String);
    this.name = new Label(x.name, team.languages);
    this.options = new ModelOptions(x.options);
    if (this.options.itemsCheckboxesNum > 0)
      this.itemsCheckboxes = this.cleanArray(x.itemsCheckboxes, l => new Label(l, team.languages)).slice(
        0,
        this.options.itemsCheckboxesNum
      );
    if (this.options.resourceCenter) this.resourceCenterFolder = new RCConfiguredFolder(x.resourceCenterFolder);
    this.customBlockActivity = new CustomBlockMeta(x.customBlockActivity, team.languages);
    if (team.multidayReports)
      this.customBlockActivityDay = new CustomBlockMeta(x.customBlockActivityDay, team.languages);
    this.color = this.clean(x.color, String);
    this.emailDefaults = new EmailData(x.emailDefaults);
    this.descriptionDefault = this.clean(x.descriptionDefault, String);
    this.suggestedFollowUp = new SuggestedFollowUpConfiguration(x.suggestedFollowUp, team.languages);
    this.pdf = new ModelPDFOptions(x.pdf, team.languages);
    this.webhookURL = this.clean(x.webhookURL, String);
    if (this.options.horace) this.horaceConfig = new HoraceConfig(x.horaceConfig);
    this.createdAt = this.clean(x.createdAt, d => new Date(d).getTime(), Date.now());
    this.createdBy = new MembershipSummary(x.createdBy);
    if (x.updatedAt) this.updatedAt = this.clean(x.updatedAt, d => new Date(d).getTime());
    if (x.updatedBy) this.updatedBy = new MembershipSummary(x.updatedBy);
  }

  safeLoad(newData: any, safeData: any, team: Team): void {
    super.safeLoad(newData, safeData, team);
    this.teamId = safeData.teamId;
    this.modelId = safeData.modelId;
    this.createdAt = safeData.createdAt;
    this.createdBy = safeData.createdBy;
    if (safeData.updatedAt) this.updatedAt = safeData.updatedAt;
    if (safeData.updatedBy) this.updatedBy = safeData.updatedBy;
  }

  validate(team: Team): string[] {
    const e = super.validate();
    if (!team.automaticIds.models && this.iE(this.modelId)) e.push('modelId');
    if (this.name.validate(team.languages).length) e.push('name');
    if (this.iE(this.color)) e.push('color');
    if (this.options.resourceCenter && this.resourceCenterFolder.validate().length) e.push('resourceCenterFolder');
    if (this.options.itemsCheckboxesNum > 0)
      this.itemsCheckboxes.forEach((label, i): void => {
        if (label.validate(team.languages).length) e.push(`itemsCheckboxes[${i}]`);
      });
    this.customBlockActivity.validate(team.languages).forEach(ea => e.push(`customBlockActivity.${ea}`));
    if (team.multidayReports)
      this.customBlockActivityDay.validate(team.languages).forEach(ea => e.push(`customBlockActivityDay.${ea}`));
    if (!areEmailsValid(this.emailDefaults)) e.push('emailDefaults');
    if (this.suggestedFollowUp) this.suggestedFollowUp.validate().forEach(ea => e.push(`suggestedFollowup.${ea}`));
    return e;
  }
}

/**
 * The options of this model.
 */
export class ModelOptions extends Resource {
  /**
   * If set, the project will be mandatory when creating a new activity and when filling out the report.
   */
  projectIsObligatory: boolean;
  /**
   * Show the picker to select the worked hours with a from/to time interval logic.
   */
  hoursFromToPicker: boolean;
  /**
   * Show the extra working hours fields while filing the report.
   */
  extraWorkingHours: boolean;
  /**
   * Show the extra working hours fields while filing the report.
   */
  billedHours: boolean;
  /**
   * Show the km of travel field while filing the report.
   */
  travellingKm: boolean;
  /**
   * Show the hours of travel field while filing the report.
   */
  travellingHours: boolean;
  /**
   * Show the internal hours of travel field while filing the report; possibly to use for HR purposes.
   */
  internalTravellingHours: boolean;
  /**
   * Show the picker to select a vehicle for each user.
   */
  vehicles: boolean;
  /**
   * If set, auto add the user who is creating the activity to the users involved in the activity.
   */
  autoAssignCreatedActivity: boolean;
  /**
   * Whether you can attach files to the reports of this model or not.
   */
  attachments: boolean;
  /**
   * If set, the model is linked to an Horace activity.
   */
  horace: boolean;
  /**
   * If set, enable the resource center for this model.
   */
  resourceCenter: boolean;
  /**
   * If set, enable the suggested follow-up for this model.
   */
  suggestedFollowUp: boolean;
  /**
   * If set, enable pickers to add items to the activity.
   */
  items: boolean;
  /**
   * If set, enable pickers to add systems to the activity.
   */
  systems: boolean;
  /**
   * If set, allow the insertion of items which aren't coming from the items list.
   */
  allowUnlistedItems: boolean;
  /**
   * If the items are allowed, the number of customizable checkboxes to display for each item used.
   */
  itemsCheckboxesNum: number;
  /**
   * Whether the activities of this model should request the customer's signature when they are confirmed.
   */
  requestSignature: boolean;
  /**
   * Whether the workflow for this model requires sending the PDF report to the customer to consider the activity Done.
   */
  requireSendingReport: boolean;
  /**
   * Optionally filter by type the customers that can be target of activities of this model.
   */
  targetType: CustomerTypes | null;
  /**
   * Whether to show internal notes about the activity's target.
   */
  showInternalNotes: boolean;

  load(x: any): void {
    super.load(x);
    this.projectIsObligatory = this.clean(x.projectIsObligatory, Boolean);
    this.hoursFromToPicker = this.clean(x.hoursFromToPicker, Boolean);
    this.extraWorkingHours = this.clean(x.extraWorkingHours, Boolean);
    this.billedHours = this.clean(x.billedHours, Boolean);
    this.travellingKm = this.clean(x.travellingKm, Boolean);
    this.travellingHours = this.clean(x.travellingHours, Boolean);
    this.internalTravellingHours = this.clean(x.internalTravellingHours, Boolean);
    this.vehicles = this.clean(x.vehicles, Boolean);
    this.autoAssignCreatedActivity = this.clean(x.autoAssignCreatedActivity, Boolean);
    this.attachments = Boolean(x.attachments);
    this.horace = this.clean(x.horace, Boolean);
    this.resourceCenter = this.clean(x.resourceCenter, Boolean);
    this.suggestedFollowUp = this.clean(x.suggestedFollowUp, Boolean);
    this.items = this.clean(x.items, Boolean);
    this.systems = this.clean(x.systems, Boolean);
    this.allowUnlistedItems = this.clean(x.allowUnlistedItems, Boolean);
    this.itemsCheckboxesNum = this.items ? this.clean(x.itemsCheckboxesNum, Number, 0) : 0;
    this.requestSignature = this.clean(x.requestSignature, Boolean);
    this.requireSendingReport = this.clean(x.requireSendingReport, Boolean);
    this.targetType = this.clean(x.targetType, String);
    this.showInternalNotes = this.clean(x.showInternalNotes, Boolean);
  }
}

/**
 * The configuration for the SuggestedFollowUp for activities of this model.
 */
export class SuggestedFollowUpConfiguration extends Resource {
  /**
   * Default values for email sending.
   */
  emailDefaults: EmailData;
  /**
   * The template for the suggested follow-up PDF: a list of sections.
   */
  template: PDFTemplateSection[];

  load(x: any, languages: Languages): void {
    super.load(x);
    this.emailDefaults = new EmailData(x.emailDefaults);
    this.template = this.cleanArray(x.template, section => new PDFTemplateSection(section, languages));
  }

  validate(): string[] {
    const e = super.validate();
    if (!areEmailsValid(this.emailDefaults)) e.push('emailDefaults');
    return e;
  }
}

/**
 * The configuration of the link with Horace.
 */
export class HoraceConfig extends Resource {
  /**
   * The method to calculate the duration of a track in Horace.
   */
  trackDurationConfig: DurationCalcUnits;
  /**
   * The method to calculate the billed hours of a track in Horace.
   */
  trackBilledHoursConfig: DurationCalcUnits;

  load(x: any): void {
    super.load(x);
    this.trackDurationConfig = new DurationCalcUnits(x.trackDurationConfig);
    this.trackBilledHoursConfig = new DurationCalcUnits(x.trackBilledHoursConfig);
  }
}

/**
 * The units that compose the calculation of a duration.
 */
export class DurationCalcUnits extends Resource {
  /**
   * If true, include the main field to calculate the duration.
   */
  main: boolean;
  /**
   * If true, include the extra field to calculate the duration.
   */
  extra: boolean;
  /**
   * If true, include the travelling field to calculate the duration.
   */
  travelling: boolean;
  /**
   * If true, include the internal travelling field to calculate the duration.
   */
  internalTravelling: boolean;

  load(x: any): void {
    super.load(x);
    this.main = this.clean(x.main, Boolean);
    this.extra = this.clean(x.extra, Boolean);
    this.travelling = this.clean(x.travelling, Boolean);
    this.internalTravelling = this.clean(x.internalTravelling, Boolean);
  }
}

/**
 * The options for the PDF report of the activities of this model.
 */
export class ModelPDFOptions extends Resource {
  /**
   * The info to show at the top of the PDF report. Fallback to team's info.
   */
  info: Label;
  /**
   * The content to show in the PDF report's header.
   */
  header: Label;
  /**
   * The content to show in the PDF report's footer.
   */
  footer: Label;
  /**
   * The template for the PDF reports: a list of sections.
   */
  template: PDFTemplateSection[];
  /**
   * Whether to show in the PDF the detail about the model's color.
   */
  showColorDetail: boolean;
  /**
   * Whether to show in the signature (if available) of the user who completed the activity.
   */
  showCompletedBySignature: boolean;
  /**
   * The name that the PDF report should get when sent or downloaded.
   */
  filename: Label;

  load(x: any, languages: Languages): void {
    super.load(x);
    this.info = new Label(x.info, languages);
    this.header = new Label(x.header, languages);
    this.footer = new Label(x.footer, languages);
    this.template = this.cleanArray(x.template, section => new PDFTemplateSection(section, languages));
    this.showColorDetail = this.clean(x.showColorDetail, Boolean);
    this.showCompletedBySignature = this.clean(x.showCompletedBySignature, Boolean);
    this.filename = new Label(x.filename, languages);
  }
}

/**
 * A brief representation of a Model.
 */
export class ModelSummary extends Resource {
  /**
   * The id of the model.
   */
  modelId: string;
  /**
   * The name of the model. Support multilanguage.
   */
  name: Label;
  /**
   * Primary color for the model.
   */
  color: string;

  load(x: any, languages: Languages): void {
    super.load(x, languages);
    this.modelId = this.clean(x.modelId, String);
    this.name = new Label(x.name, languages);
    this.color = this.clean(x.color, String);
  }
}

export const areEmailsValid = (data: EmailData): boolean => {
  const emailRegex = /^[A-Za-z0-9._%+-]{2,}@[a-zA-Z0-9-_.]{2,}\.[a-zA-Z]{2,}$/;

  let to: string[] = [];
  let cc: string[] = [];
  let bcc: string[] = [];

  data.to.map(email => (to = to.concat(email.split(/[,;]/gm))));
  data.cc.map(email => (cc = cc.concat(email.split(/[,;]/gm))));
  data.bcc.map(email => (bcc = bcc.concat(email.split(/[,;]/gm))));

  return [...to, ...cc, ...bcc]
    .map(email => email.trim())
    .filter(email => email)
    .every(email => emailRegex.test(email));
};
