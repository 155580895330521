/**
 * The resources supporting the delta mechanism.
 */
export enum DeltaResources {
  MEMBERSHIPS = 'MEMBERSHIPS',
  MODELS = 'MODELS',
  ACTIVITIES = 'ACTIVITIES',
  ITEMS = 'ITEMS',
  SYSTEMS = 'SYSTEMS',
  SYSTEMS_INSTALLED = 'SYSTEMS_INSTALLED',
  GENERIC_PROJECTS = 'GENERIC_PROJECTS',
  CUSTOMERS = 'CUSTOMERS',
  DESTINATIONS = 'DESTINATIONS',
  PROJECTS = 'PROJECTS',
  CONTACTS = 'CONTACTS'
}
