import { CacheableResource } from '@idea-ionic/common';

import { DeltaResources } from '@models/deltaResources.enum';
import { Membership } from '@models/membership.model';
import { Customer } from '@models/customer.model';
import { Contact } from '@models/contact.model';
import { Model } from '@models/model.model';
import { Activity, ActivitySummary } from '@models/activity.model';
import { Item } from '@models/item.model';
import { GenericProject, Project } from '@models/project.model';
import { Destination } from '@models/destination.model';
import { System } from '@models/system.model';
import { SystemInstalled } from '@models/systemInstalled.model';

export class MembershipsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.MEMBERSHIPS, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/memberships`;
  }
  public elementURL(element: Membership): string {
    return `teams/${this.teamId}/memberships/${element.userId}`;
  }
  public findIndexInList(list: Membership[], element: Membership): number {
    return list.findIndex(x => x.userId === element.userId);
  }
  public sort(a: Membership, b: Membership): number {
    return a.name.localeCompare(b.name);
  }
}

export class ModelsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.MODELS, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/models`;
  }
  public elementURL(element: Model): string {
    return `teams/${this.teamId}/models/${element.modelId}`;
  }
  public findIndexInList(list: Model[], element: Model): number {
    return list.findIndex(x => x.modelId === element.modelId);
  }
  public sort(a: Model, b: Model): number {
    return a.modelId.localeCompare(b.modelId);
  }
}

export class ActivitiesCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.ACTIVITIES, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/activities`;
  }
  public elementURL(element: Activity): string {
    return `teams/${this.teamId}/activities/${element.activityId}`;
  }
  public findIndexInList(list: ActivitySummary[], element: ActivitySummary): number {
    return list.findIndex(x => x.activityId === element.activityId);
  }
  public sort(): number {
    return; // not sorted
  }
}

export class ItemsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.ITEMS, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/items`;
  }
  public elementURL(element: Item): string {
    return `teams/${this.teamId}/items/${element.itemId}`;
  }
  public findIndexInList(list: Item[], element: Item): number {
    return list.findIndex(x => x.itemId === element.itemId);
  }
  public sort(a: Item, b: Item): number {
    return a.name.localeCompare(b.name);
  }
}

export class SystemsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.SYSTEMS, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/systems`;
  }
  public elementURL(element: System): string {
    return `teams/${this.teamId}/systems/${element.systemId}`;
  }
  public findIndexInList(list: System[], element: System): number {
    return list.findIndex(x => x.systemId === element.systemId);
  }
  public sort(a: System, b: System): number {
    return a.getName().localeCompare(b.getName());
  }
}

export class SystemsInstalledCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.SYSTEMS_INSTALLED, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/systemsInstalled`;
  }
  public elementURL(element: SystemInstalled): string {
    return `teams/${this.teamId}/systemsInstalled/${element.systemInstalledId}`;
  }
  public findIndexInList(list: SystemInstalled[], element: SystemInstalled): number {
    return list.findIndex(x => x.systemInstalledId === element.systemInstalledId);
  }
  public sort(a: SystemInstalled, b: SystemInstalled): number {
    return a.getName().localeCompare(b.getName());
  }
}

export class GenericProjectsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.GENERIC_PROJECTS, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/projects`;
  }
  public elementURL(element: GenericProject): string {
    return `teams/${this.teamId}/projects/${element.projectId}`;
  }
  public findIndexInList(list: GenericProject[], element: GenericProject): number {
    return list.findIndex(x => x.projectId === element.projectId);
  }
  public sort(a: GenericProject, b: GenericProject): number {
    return a.name.localeCompare(b.name);
  }
}

export class CustomersCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.CUSTOMERS, description);
  }
  public listURL(): string {
    return `teams/${this.teamId}/customers`;
  }
  public elementURL(element: Customer): string {
    return `teams/${this.teamId}/customers/${element.customerId}`;
  }
  public findIndexInList(list: Customer[], element: Customer): number {
    return list.findIndex(x => x.customerId === element.customerId);
  }
  public sort(a: Customer, b: Customer): number {
    return a.businessName.localeCompare(b.businessName);
  }
}

export class DestinationsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.DESTINATIONS, description);
  }
  public listURL(element: Destination): string {
    const customerId = element.teamCustomerId.slice(this.teamId.length + 1);
    return `teams/${this.teamId}/customers/${customerId}/destinations`;
  }
  public elementURL(element: Destination): string {
    const customerId = element.teamCustomerId.slice(this.teamId.length + 1);
    return `teams/${this.teamId}/customers/${customerId}/destinations/${element.destinationId}`;
  }
  public findIndexInList(list: Destination[], element: Destination): number {
    return list.findIndex(
      x => x.teamCustomerId === element.teamCustomerId && x.destinationId === element.destinationId
    );
  }
  public sort(a: Destination, b: Destination): number {
    return a.name.localeCompare(b.name);
  }
}

export class ProjectsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.PROJECTS, description);
  }
  public listURL(element: Project): string {
    const customerId = element.teamCustomerId.slice(this.teamId.length + 1);
    return `teams/${this.teamId}/customers/${customerId}/projects`;
  }
  public elementURL(element: Project): string {
    const customerId = element.teamCustomerId.slice(this.teamId.length + 1);
    return `teams/${this.teamId}/customers/${customerId}/projects/${element.projectId}`;
  }
  public findIndexInList(list: Project[], element: Project): number {
    return list.findIndex(x => x.teamCustomerId === element.teamCustomerId && x.projectId === element.projectId);
  }
  public sort(a: Project, b: Project): number {
    return a.name.localeCompare(b.name);
  }
}

export class ContactsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.CONTACTS, description);
  }
  public listURL(element: Contact): string {
    const customerId = element.teamCustomerId.slice(this.teamId.length + 1);
    return `teams/${this.teamId}/customers/${customerId}/contacts`;
  }
  public elementURL(element: Contact): string {
    const customerId = element.teamCustomerId.slice(this.teamId.length + 1);
    return `teams/${this.teamId}/customers/${customerId}/contacts/${element.contactId}`;
  }
  public findIndexInList(list: Contact[], element: Contact): number {
    return list.findIndex(x => x.teamCustomerId === element.teamCustomerId && x.contactId === element.contactId);
  }
  public sort(a: Contact, b: Contact): number {
    return a.name.localeCompare(b.name);
  }
}
