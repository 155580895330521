import { epochDateTime, MembershipSummary, Resource } from 'idea-toolbox';

import { Team } from './team.model';

/**
 * Table: `scarlett_teams_items`.
 *
 * Indexes:
 *    - `teamId-name-index-2`; includes: uoms, barcodes.
 */
export class Item extends Resource {
  /**
   * The id of the team owning the item.
   */
  teamId: string;
  /**
   * The id of the item.
   */
  itemId: string;
  /**
   * The name of the item.
   */
  name: string;
  /**
   * The description of the item.
   */
  description: string;
  /**
   * The unit of measurement of the item.
   */
  uoms: string[];
  /**
   * The barcodes to identify the item.
   */
  barcodes: string[];
  /**
   * Timestamp of creation.
   */
  createdAt: epochDateTime;
  /**
   * The user who created the item.
   */
  createdBy: MembershipSummary;
  /**
   * Timestamp of last update.
   */
  updatedAt?: epochDateTime;
  /**
   * The user who lastly updated the item.
   */
  updatedBy?: MembershipSummary;

  load(x: any): void {
    super.load(x);
    this.teamId = this.clean(x.teamId, String);
    this.itemId = this.clean(x.itemId, String);
    this.name = this.clean(x.name, String);
    this.description = this.clean(x.description, String);
    this.uoms = Array.from(new Set(this.cleanArray(x.uoms, String)));
    this.barcodes = this.cleanArray(x.barcodes, String);
    this.createdAt = this.clean(x.createdAt, d => new Date(d).getTime(), Date.now());
    this.createdBy = new MembershipSummary(x.createdBy);
    if (x.updatedAt) this.updatedAt = this.clean(x.updatedAt, d => new Date(d).getTime());
    if (x.updatedBy) this.updatedBy = new MembershipSummary(x.updatedBy);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.teamId = safeData.teamId;
    this.itemId = safeData.itemId;
    this.createdAt = safeData.createdAt;
    this.createdBy = safeData.createdBy;
    if (safeData.updatedAt) this.updatedAt = safeData.updatedAt;
    if (safeData.updatedBy) this.updatedBy = safeData.updatedBy;
  }

  validate(team: Team): string[] {
    const e = super.validate();
    if (!team.automaticIds.items && this.iE(this.itemId)) e.push('itemId');
    if (this.iE(this.name)) e.push('name');
    return e;
  }

  /**
   * Return an exportable flat version of the resource.
   */
  exportFlat(): ItemFlat {
    return new ItemFlat(this);
  }

  /**
   * Import a flat structure and set the internal attributes accordingly.
   */
  importFlat(x: ItemFlat): void {
    this.itemId = this.clean(x['ID item'], String);
    this.name = this.clean(x['Name'], String);
    this.description = this.clean(x['Description'], String);
    this.uoms = x['Units of measurement'] ? this.cleanArray(x['Units of measurement'].split(','), String) : [];
    this.barcodes = x['Barcodes'] ? this.cleanArray(x['Barcodes'].split(','), String) : [];
  }
}

/**
 * An item used/stored in some quantity, based on the context.
 */
export class ItemUsed extends Resource {
  /**
   * The id of the item.
   */
  itemId: string;
  /**
   * The name of the item.
   */
  name: string;
  /**
   * The unit of measurement of the item with which the item is stocked.
   */
  uom: string;
  /**
   * The quantity with which the item is stocked.
   */
  qty: number;
  /**
   * If set, the item has been inserted manually and don't come from the main list.
   */
  unlisted?: boolean;
  /**
   * The values for the custom checkboxes defined in the activity's model.
   */
  checkboxes: boolean[];
  /**
   * The notes of the item in report. Max 500 chars.
   */
  notes: string;

  load(x: any): void {
    super.load(x);
    this.itemId = this.clean(x.itemId, String);
    this.name = this.clean(x.name, String);
    this.uom = this.clean(x.uom, String);
    this.qty = this.clean(x.qty, Number, 0);
    if (x.unlisted) this.unlisted = true;
    this.checkboxes = (x.checkboxes || []).map((c: boolean) => Boolean(c));
    this.notes = this.clean(x.notes, s => String(s).slice(0, 500));
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.itemId = safeData.itemId;
    this.name = safeData.name;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.itemId)) e.push('itemId');
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.qty)) e.push('qty');
    return e;
  }
}

/**
 * A brief representation of an Item.
 */
export class ItemSummary extends Resource {
  /**
   * The id of the item.
   */
  itemId: string;
  /**
   * The name of the item.
   */
  name: string;
  /**
   * The unit of measurement of the item.
   */
  uoms: string[];
  /**
   * The barcodes to identify the item.
   */
  barcodes: string[];

  load(x: any): void {
    super.load(x);
    this.itemId = this.clean(x.itemId, String);
    this.name = this.clean(x.name, String);
    this.uoms = this.cleanArray(x.uoms, String);
    this.barcodes = this.cleanArray(x.barcodes, String);
  }
}

/**
 * A flat version of the resource, useful for exports.
 */
export class ItemFlat {
  'ID item': string;
  Name: string;
  Description: string;
  'Units of measurement': string;
  'Barcodes': string;

  constructor(x?: Item) {
    x = x || ({} as any);
    this['ID item'] = x.itemId;
    this['Name'] = x.name;
    this['Description'] = x.description;
    this['Units of measurement'] = (x.uoms || []).join(', ');
    this['Barcodes'] = (x.barcodes || []).join(', ');
  }
}
